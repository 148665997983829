import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import {
  ITerminologyPayload,
  ITerminologyRow,
} from '../../datatypes/Terminology';

interface IProps {
  show: boolean;
  toggleDeleteModal: () => void;
  handleSubmit: () => void;
  selectedIdDetails: ITerminologyRow[];
  deleteTerminologyDetail: ITerminologyPayload['deleteTerminologyStatus'];
}

const DeleteModal = ({
  show,
  toggleDeleteModal,
  handleSubmit,
  selectedIdDetails,
  deleteTerminologyDetail,
}: IProps) => {
  return (
    <Modal show={show} onHide={toggleDeleteModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Do you want to delete terminology?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-template">
          You will delete
          {selectedIdDetails?.length > 0 &&
            selectedIdDetails.map(
              (terminologyDetail: ITerminologyRow, index: number) => (
                <span className="mx-2">
                  {terminologyDetail.term}
                  {selectedIdDetails?.length !== index + 1 && ', '}
                </span>
              )
            )}
          terminology !
        </div>
        <div></div>
      </Modal.Body>
      <Modal.Footer>
        <FowButton variant="secondary" onClick={toggleDeleteModal}>
          Close
        </FowButton>
        <FowButton
          variant="danger"
          onClick={handleSubmit}
          isLoading={deleteTerminologyDetail?.loading}
        >
          Delete
        </FowButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
