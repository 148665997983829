import useAxiosService from '../services/axios.service';

const useUserApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //api call for getting admin users list
  const getAdminUsers = async (params?: any) => {
    return await getUserHttpClient()
      .get('/users', { params: params ? params : {} })
      .then((response) => response?.data);
  };

  //api call for saving new admin user
  const saveAdminUser = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/users/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response?.data);
  };

  //api call for updating admin user
  const updateAdminUser = async (data: any, id: string) => {
    return await getUserHttpClient()
      .put('/users/' + id, data)
      .then((response) => response?.data);
  };

  //api call for deleting admin user
  const deleteAdminUser = async (id: string) => {
    return await getUserHttpClient()
      .delete('/users/' + id)
      .then((response) => response?.data);
  };

  //api call for getting user details
  const getUserDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/users/' + id)
      .then((response) => response?.data);
  };

  //api call for getting member users list
  const getMemberUsersList = async (params?: any) => {
    return await getUserHttpClient()
      .get('/users/members', { params: params ? params : {} })
      .then((response) => response?.data);
  };

  //update user role
  const updateUserRole = async (data: any, id: string) => {
    return await getUserHttpClient()
      .post('/users/' + id + '/role', data)
      .then((response) => response?.data);
  };

  return {
    getAdminUsers,
    getUserDetails,
    saveAdminUser,
    updateAdminUser,
    getMemberUsersList,
    deleteAdminUser,
    updateUserRole,
  };
};

export default useUserApi;
