import DataTable from 'react-data-table-component';
import { Card } from 'react-bootstrap';
import { dtCustomStyle, Dtspinner } from '../../helpers/DataTableHelper';
import {
  IMissingWillSearchPayload,
  IShowDeleteModal,
  IWillDetailColumn,
  IWillDetailRow,
  IWillPagination,
  IWillSelectedRows,
} from '../../datatypes/MisssingWillSearch';
import { deleteMissingWillSearchData } from '../../redux/slices/missingWillSearchSlice';
import DeleteModal from './DeleteModal';
import { useAppDispatch } from '../../redux/hooks';
import React, { useEffect } from 'react';
import { PageOptions } from '../../constants/Common';
import moment from 'moment';
import { ISortParams } from 'src/datatypes/CommonTypes';
import ActionButton from '../common/ActionButton';
import { canUserAccess } from 'src/helpers/common';

interface IProps {
  willList: IMissingWillSearchPayload['willList'];
  deleteWillDetail: IMissingWillSearchPayload['deleteWill'];
  showDeleteModal: IShowDeleteModal;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<IShowDeleteModal>>;
  setPagination: React.Dispatch<React.SetStateAction<IWillPagination>>;
  setSort: React.Dispatch<React.SetStateAction<ISortParams>>;
}

const Listing = ({
  willList,
  deleteWillDetail,
  showDeleteModal,
  setShowDeleteModal,
  setPagination,
  setSort,
}: IProps) => {
  const dispatch = useAppDispatch();

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  const selectedWillId = (rows: IWillDetailRow[]) => {
    setShowDeleteModal((prev: IShowDeleteModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  const handleRowSelectedChange = ({ selectedRows }: IWillSelectedRows) => {
    setShowDeleteModal({
      ...showDeleteModal,
      idDetails: selectedRows,
    });
  };

  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((willDetails: IWillDetailRow) => willDetails.id)
      .join(',');
    dispatch(deleteMissingWillSearchData(selectedIds));
  };

  const openDeleteModal = (row: IWillDetailRow) => {
    selectedWillId([row]);
    toggleDeleteModal();
  };

  useEffect(() => {
    if (deleteWillDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteModal) => ({
        ...prev,
        idDetails: [],
      }));
      toggleDeleteModal();
    }
    // eslint-disable-next-line
  }, [deleteWillDetail?.success]);

  const columns: IWillDetailColumn[] = [
    {
      name: 'Actions',
      center: true,
      selector: (row: IWillDetailRow) => row['actions'],
      width: '6.5rem',
      cell: (row: IWillDetailRow) => (
        <div className="mx-auto">
          <ActionButton
            action="view"
            redirectTo={`/missing-will-searches/${row?.id}/view`}
            permissions={['view-missing-will-search-details']}
          />
          <ActionButton
            action="delete"
            onClick={() => openDeleteModal(row)}
            permissions={['delete-missing-will-search']}
            className="ml-1"
          />
        </div>
      ),
      omit: !canUserAccess([
        'view-missing-will-search-details',
        'delete-missing-will-search',
      ]),
      right: true,
    },
    {
      name: 'Searcher Name',
      selector: (row: IWillDetailRow) => row['searcher_name'],
      sortField: 's_first_name',
      sortable: true,
      width: '8.5rem',
    },
    {
      name: 'Deceased Name',
      selector: (row: IWillDetailRow) => row['deceased_name'],
      sortField: 'd_first_name',
      sortable: true,
      width: '8.5rem',
    },
    {
      name: 'Searcher Contact',
      selector: (row: IWillDetailRow) => row['searcher_contact'],
      sortField: 's_email',
      sortable: true,
      cell: (row: IWillDetailRow) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '3px 0px',
          }}
        >
          <div>{row['searcher_email']}</div>
          <div style={{ color: 'gray' }}>{row['searcher_phone']}</div>
        </div>
      ),
      width: '15.5rem',
    },
    {
      name: 'Searcher Address',
      selector: (row: IWillDetailRow) => row['searcher_address'],
      sortField: 'address_1',
      sortable: true,
      cell: (row: IWillDetailRow) => (
        <>
          {row?.searcher_address_1 ?? ''}
          {row?.searcher_address_2 ? ', ' + row?.searcher_address_2 : ','}
          <br />
          {row?.searcher_city}
          {row?.searcher_state_name ? ', ' + row?.searcher_state_name : ''}
          {row?.searcher_zipcode ? ' - ' + row?.searcher_zipcode : ''}
          {row?.searcher_country ? ', ' + row?.searcher_country + '.' : ''}
          {row?.searcher_county_name && (
            <>
              <br />
              {row?.searcher_county_name + ' County'}
            </>
          )}
        </>
      ),
    },
    {
      name: 'Deceased Birth Date & Death Date',
      selector: (row: IWillDetailRow) => row['searcher_contact'],
      sortField: 'd_birth_date',
      sortable: true,
      cell: (row: IWillDetailRow) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '3px 0px',
          }}
        >
          <span className="white-space-pre">
            {moment
              .utc(moment(row['deceased_birth_date']))
              .local()
              ?.format('MMM DD, YYYY') ?? 'N/A'}
          </span>
          <span className="white-space-pre" style={{ color: 'grey' }}>
            {moment
              .utc(moment(row['deceased_death_date']))
              .local()
              ?.format('MMM DD, YYYY') ?? 'N/A'}
          </span>
        </div>
      ),
      width: '12.5rem',
    },
    {
      name: 'Will Found?',
      selector: (row: IWillDetailRow) => row['will_found'],
      sortField: 'will_found',
      sortable: true,
      width: '6.5rem',
      center: true,
      cell: (row: IWillDetailRow) =>
        row?.will_found ? (
          <span className="badge badge-success">Found</span>
        ) : (
          <span className="badge badge-danger">Not Found</span>
        ),
    },
    {
      name: 'Created At',
      selector: (row: IWillDetailRow) => row['created_at'],
      sortField: 'created_at',
      sortable: true,
      cell: (row: IWillDetailRow) => (
        <span className="white-space-pre">
          {moment
            .utc(new Date(row['created_at']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
      width: '9.5rem',
      right: true,
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={willList.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              columns={columns}
              paginationTotalRows={willList.data.total}
              onChangeRowsPerPage={(new_per_page: number) =>
                setPagination((prev: IWillPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }))
              }
              data={willList.data?.data}
              customStyles={dtCustomStyle}
              selectableRows={canUserAccess(['delete-missing-will-search'])}
              onChangePage={(page: number) =>
                setPagination((prev: IWillPagination) => ({
                  ...prev,
                  page: page,
                }))
              }
              onSelectedRowsChange={handleRowSelectedChange}
              onSort={(column, direction) => {
                setSort({ orderBy: column.sortField, order: direction });
              }}
              sortServer
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteWillDetail={deleteWillDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
