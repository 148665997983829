import Dashboard from '../views/dashboard/Dashboard';
import PagesListing from '../views/pages/PageListing';
import PagesDetails from '../views/pages/PageDetails';

import NewQuestionListing from '../views/questions/NewQuestionListing';
import NewQuestionDetails from '../views/questions/NewQuestionDetails';

import StateListing from '../views/states/StateListing';

import CountyListing from '../views/counties/CountyListing';
import ZipcodeListing from '../views/zipcodes/ZipcodeListing';

import MissingWillSearchListing from '../views/missing-will-searches/MissingWillSearchListing';
import MissingWillSearchDetails from '../views/missing-will-searches/MissingWillSearchDetails';

import InvoiceListing from '../views/invoices/InvoiceListing';
import InvoiceDetails from '../views/invoices/InvoiceDetail';
import GenerateSearchPinCode from '../views/invoices/GenerateSearchPinCode';

import DeathNoticeListing from '../views/death-notice/DeathNoticeListing';
import DeathNoticeDetails from '../views/death-notice/DeathNoticeDetail';

import TemplateListing from '../views/templates/TemplateListing';
import TemplateDetails from '../views/templates/TemplateDetails';

import TestimonialListing from '../views/testimonials/TestimonialListing';
import TestimonialDetails from '../views/testimonials/TestimonialDetails';

import CharityListing from '../views/charities/CharityListing';
import CharityDetails from '../views/charities/CharityDetails';

import CharityCategoriesListing from '../views/charity-categories/CharityCategoryListing';
import CharityCategoriesDetails from '../views/charity-categories/CharityCategoryDetails';
import PricingDetails from '../views/pricings/PricingDetails';
import PricingListing from '../views/pricings/PricingListing';
import AdminUserListing from '../views/admin-users/UserListing';
import AdminUserDetails from '../views/admin-users/UserDetails';

import MemberListing from '../views/member-users/MemberListing';
import MemberDetails from '../views/member-users/MemberDetails';

import TerminologiesListing from '../views/terminologies/TerminologyListing';
import TerminologiesDetails from '../views/terminologies/TerminologyDetails';

import FaqsListing from '../views/faqs/FaqsListing';
import FaqDetails from '../views/faqs/FaqDetails';

import MetaDetails from '../views/metadata/MetaDetails';
import MetaListing from '../views/metadata/MetaListing';

import LivingWillFormListing from '../views/living-will-forms/LivingWillFormListing';
import LivingWillFormDetails from '../views/living-will-forms/LivingWillFormDetails';

import LivingWillListing from '../views/will-registration/WillRegistrationListing';
import LivingWillDetails from '../views/will-registration/WillRegistrationDetails';

import AdminLogout from '../views/auth/AdminLogout';

import WillCreatorsListing from '../views/will-creators/WillCreatorsListing';
import WillCreatorDetails from '../views/will-creators/WillCreatorDetails';
import ObituaryListing from '../views/obituaries/ObituaryListing';
import ObituaryDetails from '../views/obituaries/ObituaryDetails';

import PartnershipsListing from '../views/charity-partnerships/CharityPartnershipListing';
import CharityPartnershipDetails from '../views/charity-partnerships/CharityPartnershipDetails';
import WillCreatorAnswers from '../views/will-creators/WillCreatorAnswers';
import AccessDenied from 'src/views/AccessDenied';

import RoleListing from 'src/views/roles/RoleListing';
import RoleDetails from 'src/views/roles/RoleDetails';

import PermissionListing from 'src/views/permissions/PermissionListing';
import PermissionDetails from 'src/views/permissions/PermissionDetails';

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: Dashboard,
    permissions: ['view-dashboard'],
  },
  {
    path: '/pages',
    exact: true,
    name: 'Page Listing',
    component: PagesListing,
    permissions: ['view-pages'],
  },
  {
    path: '/pages/:id/edit',
    name: 'Edit Page',
    component: PagesDetails,
    permissions: ['update-page'],
  },
  {
    path: '/pages/:id/view',
    name: 'View Page',
    component: PagesDetails,
    permissions: ['view-page-details'],
  },
  {
    path: '/pages/new',
    name: 'New Page',
    component: PagesDetails,
    permissions: ['create-page'],
  },
  {
    path: '/admin-users',
    exact: true,
    name: 'Admin User Listing',
    component: AdminUserListing,
    permissions: ['view-admin-users'],
  },
  {
    path: '/admin-users/:id/view',
    exact: true,
    name: 'Admin User Details',
    component: AdminUserDetails,
    permissions: ['view-user-details'],
  },
  {
    path: '/admin-users/:id/edit',
    exact: true,
    name: 'Admin User Details',
    component: AdminUserDetails,
    permissions: ['update-user'],
  },
  {
    path: '/admin-users/new',
    exact: true,
    name: 'Admin User Details',
    component: AdminUserDetails,
    permissions: ['create-user'],
  },
  {
    path: '/member-users',
    exact: true,
    name: 'Member User Listing',
    component: MemberListing,
    permissions: ['view-members'],
  },
  {
    path: '/member-users/:id/view',
    exact: true,
    name: 'Member User Details',
    component: MemberDetails,
    permissions: ['view-user-details'],
  },
  {
    path: '/charities',
    exact: true,
    name: 'Charity Listing',
    component: CharityListing,
    permissions: ['view-charities'],
  },
  {
    path: '/partnerships',
    exact: true,
    name: 'Partnerships Listing',
    component: PartnershipsListing,
    permissions: ['view-partnerships'],
  },
  {
    path: '/missing-will-searches',
    exact: true,
    name: 'Missing Wills',
    component: MissingWillSearchListing,
    permissions: ['view-missing-will-search'],
  },
  {
    path: '/missing-will-searches/:id/view',
    exact: true,
    name: 'Missing Wills',
    component: MissingWillSearchDetails,
    permissions: ['view-missing-will-search-details'],
  },
  {
    path: '/invoices',
    exact: true,
    name: 'Invoices',
    component: InvoiceListing,
    permissions: ['view-invoices'],
  },
  {
    path: '/invoices/:id/view',
    exact: true,
    name: 'Invoice',
    component: InvoiceDetails,
    permissions: ['view-invoice-details'],
  },
  {
    path: '/invoices/generate-search-pin-code',
    exact: true,
    name: 'Generate Search Pin-code',
    component: GenerateSearchPinCode,
    permissions: ['view-invoices'],
  },
  {
    path: '/pricing',
    exact: true,
    name: 'Pricing',
    component: PricingListing,
    permissions: ['view-pricing'],
  },
  {
    path: '/pricing/new',
    exact: true,
    name: 'Pricing',
    component: PricingDetails,
    permissions: ['create-pricing'],
  },
  {
    path: '/pricing/:id/view',
    exact: true,
    name: 'Pricing',
    component: PricingDetails,
    permissions: ['view-pricing-details'],
  },
  {
    path: '/pricing/:id/edit',
    exact: true,
    name: 'Pricing',
    component: PricingDetails,
    permissions: ['update-pricing'],
  },
  {
    path: '/death-notices',
    exact: true,
    name: 'Death Notices',
    component: DeathNoticeListing,
    permissions: ['view-death-notices'],
  },
  {
    path: '/death-notices/:id/view',
    exact: true,
    name: 'Death Notices',
    component: DeathNoticeDetails,
    permissions: ['view-death-notice-details'],
  },
  {
    path: '/obituaries',
    exact: true,
    name: 'Obituaries',
    component: ObituaryListing,
    permissions: ['view-obituaries'],
  },
  {
    path: '/obituaries/:id/view',
    exact: true,
    name: 'Obituries view',
    component: ObituaryDetails,
    permissions: ['view-obituary-details'],
  },
  {
    path: '/obituaries/:id/edit',
    name: 'Edit Obituary Details',
    component: ObituaryDetails,
    permissions: ['update-obituary'],
  },
  {
    path: '/templates',
    exact: true,
    name: 'Email Templates',
    component: TemplateListing,
    permissions: ['view-templates'],
  },
  {
    path: '/templates/:id',
    exact: true,
    name: 'Email Templates',
    component: TemplateDetails,
    permissions: ['view-template-details'],
  },
  {
    path: '/testimonials',
    exact: true,
    name: 'Testimonials',
    component: TestimonialListing,
    permissions: ['view-testimonials'],
  },
  {
    path: '/testimonials/new',
    exact: true,
    name: 'Testimonials',
    component: TestimonialDetails,
    permissions: ['create-testimonial'],
  },
  {
    path: '/testimonials/:id/edit',
    exact: true,
    name: 'Testimonials',
    component: TestimonialDetails,
    permissions: ['update-testimonial'],
  },
  {
    path: '/testimonials/:id/view',
    exact: true,
    name: 'Testimonials',
    component: TestimonialDetails,
    permissions: ['view-testimonial-details'],
  },
  {
    path: '/charities/new',
    name: 'New Charity',
    component: CharityDetails,
    permissions: ['create-charity'],
  },
  {
    path: '/charities/:id/edit',
    name: 'Edit Charity',
    component: CharityDetails,
    permissions: ['update-charity'],
  },
  {
    path: '/charities/:id/view',
    name: 'Charity Details',
    component: CharityDetails,
    permissions: ['view-charity-details'],
  },
  {
    path: '/partnerships/new',
    name: 'New Charity',
    component: CharityPartnershipDetails,
    permissions: ['create-partnership'],
  },
  {
    path: '/partnerships/:id/edit',
    name: 'Edit Charity',
    component: CharityPartnershipDetails,
    permissions: ['update-partnership'],
  },
  {
    path: '/partnerships/:id/view',
    name: 'Charity Details',
    component: CharityPartnershipDetails,
    permissions: ['view-partnership-details'],
  },
  {
    path: '/questions',
    exact: true,
    name: 'Question Listing',
    component: NewQuestionListing,
    permissions: ['view-pages'],
  },
  {
    path: '/questions/new',
    name: 'New Question',
    component: NewQuestionDetails,
    permissions: ['create-question'],
  },
  {
    path: '/questions/:id/edit',
    name: 'Edit Question',
    component: NewQuestionDetails,
    permissions: ['update-question'],
  },
  {
    path: '/questions/:id/view',
    name: 'Edit Question',
    component: NewQuestionDetails,
    permissions: ['view-question-details'],
  },
  {
    path: '/charity-categories',
    exact: true,
    name: 'Charity Category Listing',
    component: CharityCategoriesListing,
    permissions: ['view-charity-categories'],
  },
  {
    path: '/charity-categories/new',
    exact: true,
    name: 'New Charity Category',
    component: CharityCategoriesDetails,
    permissions: ['create-charity-category'],
  },
  {
    path: '/charity-categories/:id/edit',
    name: 'Edit Charity Category',
    component: CharityCategoriesDetails,
    permissions: ['update-charity-category'],
  },
  {
    path: '/charity-categories/:id/view',
    name: 'View Charity Category',
    component: CharityCategoriesDetails,
    permissions: ['view-charity-category-details'],
  },
  {
    path: '/living-will-forms',
    exact: true,
    name: 'Living Will Forms',
    component: LivingWillFormListing,
    permissions: ['view-living-will-forms'],
  },
  {
    path: '/living-will-forms/new',
    exact: true,
    name: 'New Living Will Form Details',
    component: LivingWillFormDetails,
    permissions: ['create-living-will-form'],
  },
  {
    path: '/living-will-forms/:id/edit',
    name: 'Edit Living Will Form Details',
    component: LivingWillFormDetails,
    permissions: ['update-living-will-form'],
  },
  {
    path: '/living-will-forms/:id/view',
    name: 'View Living Will Form Details',
    component: LivingWillFormDetails,
    permissions: ['view-living-will-form-details'],
  },
  {
    path: '/states',
    exact: true,
    name: 'State Listing',
    component: StateListing,
  },
  {
    path: '/counties',
    exact: true,
    name: 'County Listing',
    component: CountyListing,
  },
  {
    path: '/zipcodes',
    exact: true,
    name: 'Zipcodes',
    component: ZipcodeListing,
  },
  {
    path: '/logout',
    exact: true,
    name: 'Admin Logout',
    component: AdminLogout,
  },
  {
    path: '/terminologies',
    exact: true,
    name: 'Terminologies',
    component: TerminologiesListing,
    permissions: ['view-terminologies'],
  },
  {
    path: '/terminologies/new',
    exact: true,
    name: 'Terminologies',
    component: TerminologiesDetails,
    permissions: ['create-terminology'],
  },
  {
    path: '/terminologies/:id/edit',
    exact: true,
    name: 'Terminologies',
    component: TerminologiesDetails,
    permissions: ['update-terminology'],
  },
  {
    path: '/terminologies/:id/view',
    exact: true,
    name: 'Terminologies',
    component: TerminologiesDetails,
    permissions: ['view-terminology-details'],
  },
  {
    path: '/faqs',
    exact: true,
    name: 'Faqs',
    component: FaqsListing,
    permissions: ['view-faqs'],
  },
  {
    path: '/faqs/new',
    exact: true,
    name: 'Faqs',
    component: FaqDetails,
    permissions: ['create-faq'],
  },
  {
    path: '/faqs/:id/edit',
    exact: true,
    name: 'Faqs',
    component: FaqDetails,
    permissions: ['update-faq'],
  },
  {
    path: '/faqs/:id/view',
    exact: true,
    name: 'Faqs',
    component: FaqDetails,
    permissions: ['view-faq-details'],
  },
  {
    path: '/meta-tags',
    exact: true,
    name: 'Meta Tags Details',
    component: MetaListing,
    permissions: ['view-meta-tags'],
  },
  {
    path: '/meta-tags/:id/view',
    exact: true,
    name: 'Meta Tags Details',
    component: MetaDetails,
    permissions: ['view-meta-tag-details'],
  },
  {
    path: '/meta-tags/:id/edit',
    exact: true,
    name: 'Meta Tags Details',
    component: MetaDetails,
    permissions: ['update-meta-tag'],
  },
  {
    path: '/meta-tags/new',
    exact: true,
    name: 'Meta Tags Details',
    component: MetaDetails,
    permissions: ['create-meta-tag'],
  },
  {
    path: '/living-will',
    exact: true,
    name: 'Living Will Details',
    component: LivingWillListing,
    permissions: ['view-living-will'],
  },
  {
    path: '/living-will/:id',
    exact: true,
    name: 'Living Will Details',
    component: LivingWillDetails,
    permissions: ['view-living-will-details'],
  },
  {
    path: '/will-creators',
    exact: true,
    name: 'Create Will Details',
    component: WillCreatorsListing,
    permissions: ['view-will-creators'],
  },
  {
    path: '/will-creators/:id',
    exact: true,
    name: 'Create Will Details',
    component: WillCreatorDetails,
    permissions: ['view-will-creator-details'],
  },
  {
    path: '/will-creators/:id/preview-answers',
    exact: true,
    name: 'Will Creator Answer',
    component: WillCreatorAnswers,
    permissions: ['view-will-creator-answers'],
  },
  {
    path: '/access-denied',
    exact: true,
    name: 'Will Creator Answer',
    component: AccessDenied,
  },
  {
    path: '/roles',
    exact: true,
    name: 'Roles',
    component: RoleListing,
    permissions: ['view-roles'],
  },
  {
    path: '/roles/:id/edit-permissions',
    exact: true,
    name: 'Roles Details',
    component: RoleDetails,
    permissions: ['update-role-permissions'],
  },
  {
    path: '/permissions',
    exact: true,
    name: 'Permissions',
    component: PermissionListing,
    permissions: ['view-permissions'],
  },
  {
    path: '/permissions/new',
    exact: true,
    name: 'Permission Details',
    component: PermissionDetails,
    permissions: ['create-permission'],
  },
  {
    path: '/permissions/:id/edit',
    exact: true,
    name: 'Permission Details',
    component: PermissionDetails,
    permissions: ['update-permission'],
  },
];

export default routes;
