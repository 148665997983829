import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useUserApi from '../../api/user.api';
import { IUserDetails, IUserRole, IUsersPayload } from '../../datatypes/User';

// initial redux slice state
const initialState: IUsersPayload = {
  admin: {
    adminList: {
      loading: false,
      error: false,
      data: {
        current_page: 0,
        total: 0,
        data: [],
      },
    },
    adminDetails: {
      loading: false,
      error: false,
      success: false,
      data: null,
    },
    saveUpdateAdmin: {
      loading: false,
      error: false,
      success: false,
    },
    deleteAdmin: {
      loading: false,
      error: false,
      success: false,
    },
  },
  members: {
    memberList: {
      loading: false,
      error: false,
      data: {
        current_page: 0,
        total: 0,
        data: [],
      },
    },
    memberDetails: {
      loading: false,
      error: false,
      data: null,
      success: false,
    },
    updateUserRoleStatus: {
      loading: false,
      error: false,
      success: false,
    },
  },
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetAdminUsersData: (state) => {
      state.admin.adminList.data = initialState.admin.adminList.data;
      state.admin.adminDetails = initialState.admin.adminDetails;
      state.admin.saveUpdateAdmin = initialState.admin.saveUpdateAdmin;
      state.admin.deleteAdmin = initialState.admin.deleteAdmin;
    },
    resetMemberUsersData: (state) => {
      state.members.memberList.data = initialState.members.memberList.data;
      state.members.updateUserRoleStatus =
        initialState.members.updateUserRoleStatus;
      state.members.memberDetails.data =
        initialState.members.memberDetails.data;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminUsers.pending, (state) => {
        state.admin.adminList.loading = true;
      })
      .addCase(fetchAdminUsers.fulfilled, (state, { payload }) => {
        state.admin.adminList.data.data = payload?.users ?? [];
        state.admin.adminList.data.total = payload?.total ?? 0;
        state.admin.adminList.data.current_page = payload?.current_page ?? 0;
        state.admin.adminList.loading = false;
      })
      .addCase(fetchAdminUsers.rejected, (state) => {
        state.admin.adminList.loading = false;
        state.admin.adminList.error = true;
        state.admin.adminList.data = initialState.admin.adminList.data;
      })
      .addCase(fetchMemberUsersList.pending, (state) => {
        state.members.memberList.loading = true;
      })
      .addCase(fetchMemberUsersList.fulfilled, (state, { payload }) => {
        state.members.memberList.data = payload;
        state.members.memberList.loading = false;
      })
      .addCase(fetchMemberUsersList.rejected, (state) => {
        state.members.memberList.loading = false;
        state.members.memberList.error = true;
        state.members.memberList.data = initialState.members.memberList.data;
      })
      .addCase(fetchAdminUserDetails.pending, (state) => {
        state.admin.adminDetails.loading = true;
        state.admin.adminDetails.error = false;
        state.admin.adminDetails.success = false;
        state.admin.adminDetails.data = null;
      })
      .addCase(fetchAdminUserDetails.fulfilled, (state, { payload }) => {
        state.admin.adminDetails.data = payload;
        state.admin.adminDetails.loading = false;
        state.admin.adminDetails.error = false;
        state.admin.adminDetails.success = true;
      })
      .addCase(fetchAdminUserDetails.rejected, (state) => {
        state.admin.adminDetails.loading = false;
        state.admin.adminDetails.error = true;
        state.admin.adminDetails.success = false;
        state.admin.adminDetails.data = null;
      })
      .addCase(fetchMemberUserDetails.pending, (state) => {
        state.members.memberDetails.loading = true;
        state.members.memberDetails.error = false;
        state.members.memberDetails.success = false;
        state.members.memberDetails.data = null;
      })
      .addCase(fetchMemberUserDetails.fulfilled, (state, { payload }) => {
        state.members.memberDetails.data = payload;
        state.members.memberDetails.loading = false;
        state.members.memberDetails.error = false;
        state.members.memberDetails.success = true;
      })
      .addCase(fetchMemberUserDetails.rejected, (state) => {
        state.members.memberDetails.loading = false;
        state.members.memberDetails.error = true;
        state.members.memberDetails.success = false;
        state.members.memberDetails.data = null;
      })
      .addCase(saveUpdateAdminUser.pending, (state) => {
        state.admin.saveUpdateAdmin.loading = true;
        state.admin.saveUpdateAdmin.error = false;
        state.admin.saveUpdateAdmin.success = false;
      })
      .addCase(saveUpdateAdminUser.fulfilled, (state) => {
        state.admin.saveUpdateAdmin.loading = false;
        state.admin.saveUpdateAdmin.error = false;
        state.admin.saveUpdateAdmin.success = true;
      })
      .addCase(saveUpdateAdminUser.rejected, (state) => {
        state.admin.saveUpdateAdmin.loading = false;
        state.admin.saveUpdateAdmin.error = true;
        state.admin.saveUpdateAdmin.success = false;
      })
      .addCase(deleteAdminUser.pending, (state) => {
        state.admin.deleteAdmin.loading = true;
        state.admin.deleteAdmin.error = false;
        state.admin.deleteAdmin.success = false;
      })
      .addCase(deleteAdminUser.fulfilled, (state) => {
        state.admin.deleteAdmin.loading = false;
        state.admin.deleteAdmin.error = false;
        state.admin.deleteAdmin.success = true;
      })
      .addCase(deleteAdminUser.rejected, (state) => {
        state.admin.deleteAdmin.loading = false;
        state.admin.deleteAdmin.error = true;
        state.admin.deleteAdmin.success = false;
      })
      .addCase(updateUserRole.pending, (state) => {
        state.members.updateUserRoleStatus.loading = true;
        state.members.updateUserRoleStatus.error = false;
        state.members.updateUserRoleStatus.success = false;
      })
      .addCase(updateUserRole.fulfilled, (state) => {
        state.members.updateUserRoleStatus.loading = false;
        state.members.updateUserRoleStatus.error = false;
        state.members.updateUserRoleStatus.success = true;
      })
      .addCase(updateUserRole.rejected, (state) => {
        state.members.updateUserRoleStatus.loading = false;
        state.members.updateUserRoleStatus.error = true;
        state.members.updateUserRoleStatus.success = false;
      });
  },
});

// Async API Hooks
/**
 * Fetch Admin Users List from API and save in redux store
 */
export const fetchAdminUsers = createAsyncThunk(
  'users/fetchAdminUsers',
  async (data: any, { rejectWithValue }) => {
    for (let key in data.params) {
      if (data.params[key] === '') {
        delete data.params[key];
      }
    }

    const response = await useUserApi().getAdminUsers(data.params);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Member Users List from API and save in redux store
 */
export const fetchMemberUsersList = createAsyncThunk(
  'users/fetchMemberUsersList',
  async (data: any, { rejectWithValue }) => {
    for (let key in data.params) {
      if (data.params[key] === '') {
        delete data.params[key];
      }
    }

    const response = await useUserApi().getMemberUsersList(data.params);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.users;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Admin User Details from API and save in redux store
 */
export const fetchAdminUserDetails = createAsyncThunk(
  'users/fetchAdminUserDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useUserApi().getUserDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.users;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Member User Details from API and save in redux store
 */
export const fetchMemberUserDetails = createAsyncThunk(
  'users/fetchMemberUserDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useUserApi().getUserDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.users;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Save Admin User from API and save in redux store
 */
export const saveUpdateAdminUser = createAsyncThunk(
  'users/saveUpdateAdminUser',
  async (data: IUserDetails, { rejectWithValue }) => {
    const id = data?.id;
    if (data['id']) {
      delete data['id'];
    }
    const response = id
      ? await useUserApi().updateAdminUser(data, String(id))
      : await useUserApi().saveAdminUser(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Admin User
 */
export const deleteAdminUser = createAsyncThunk(
  'users/deleteAdminUser',
  async (id: string, { rejectWithValue }) => {
    const response = await useUserApi().deleteAdminUser(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Update User Role from API and save in redux store
 */
export const updateUserRole = createAsyncThunk(
  'users/updateUserRole',
  async (data: IUserRole, { rejectWithValue }) => {
    const { user_id, ...restData } = data;

    const response = await useUserApi().updateUserRole(
      restData,
      String(user_id)
    );
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetAdminUsersData, resetMemberUsersData } = usersSlice.actions;

export default usersSlice.reducer;
