import { OptionType } from 'src/datatypes/CommonTypes';
import {
  IPermissionDetails,
  IPermissionValidations,
} from 'src/datatypes/Permission';

// initial state for form - question
export const templatePermissionDetails: IPermissionDetails = {
  name: '',
  group: '',
  guard_name: '',
};

// Options for Charity preferences selection
export const guardNameOptions: OptionType<string>[] = [
  {
    label: 'API',
    value: 'api',
  },
  {
    label: 'Web',
    value: 'web',
  },
];

// validations for permission
export const permissionValidations: IPermissionValidations = {
  name: 'required',
  group: 'required',
};
