import { useNavigate } from 'react-router-dom';
import { getRedirectRoute } from 'src/helpers/common';

const AccessDenied = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    const routeToRedirect = getRedirectRoute();

    if (routeToRedirect) {
      navigate(routeToRedirect);
      return;
    }
    navigate('/'); // Navigate to the home page
  };

  return (
    <div className="access-denied-container">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="icon-container mb-3">
          <i className="fa fa-lock fa-3x" aria-hidden="true"></i>
        </div>
        <h2>Access Denied</h2>
        <p>You do not have permission to view this page.</p>
        <button className="btn btn-primary mt-2" onClick={handleGoHome}>
          Go to Home Page
        </button>
      </div>
    </div>
  );
};

export default AccessDenied;
