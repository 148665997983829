import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import usePermissionApi from 'src/api/permission.api';
import {
  IPermissionPayload,
  ISaveUpdatePermission,
} from 'src/datatypes/Permission';

// initial / template state of permission redux slice
const initialState: IPermissionPayload = {
  permissions: {
    loading: false,
    error: false,
    success: false,
    data: [],
  },
  permissionDetails: {
    loading: false,
    error: false,
    success: false,
    data: null,
  },
  saveUpdateStatus: {
    success: false,
    loading: false,
    error: false,
  },
  deletePermissionStatus: {
    success: false,
    loading: false,
    error: false,
  },
};

// redux slice for permission
export const permissionSlice = createSlice({
  name: 'permissions',
  initialState,
  // reducers that don't require API Calls
  reducers: {
    resetPermissionDetails: (state: IPermissionPayload) => {
      state.permissionDetails = initialState.permissionDetails;
      state.saveUpdateStatus = initialState.saveUpdateStatus;
      state.deletePermissionStatus = initialState.deletePermissionStatus;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.permissions.loading = true;
        state.permissions.error = false;
        state.permissions.success = false;
      })
      .addCase(fetchPermissions.fulfilled, (state, { payload }) => {
        state.permissions.data = payload.permissions;
        state.permissions.loading = false;
        state.permissions.success = true;
      })
      .addCase(fetchPermissions.rejected, (state) => {
        state.permissions.loading = false;
        state.permissions.error = true;
      })
      .addCase(fetchPermissionDetails.pending, (state) => {
        state.permissionDetails.loading = true;
        state.permissionDetails.error = false;
        state.permissionDetails.success = false;
      })
      .addCase(fetchPermissionDetails.fulfilled, (state, { payload }) => {
        state.permissionDetails.data = payload;
        state.permissionDetails.loading = false;
        state.permissionDetails.success = true;
      })
      .addCase(fetchPermissionDetails.rejected, (state) => {
        state.permissionDetails.loading = false;
        state.permissionDetails.error = true;
      })
      .addCase(saveUpdatePermission.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(saveUpdatePermission.fulfilled, (state) => {
        state.saveUpdateStatus.success = true;
        state.saveUpdateStatus.loading = false;
      })
      .addCase(saveUpdatePermission.rejected, (state) => {
        state.saveUpdateStatus.error = true;
        state.saveUpdateStatus.loading = false;
      })
      .addCase(deletePermission.pending, (state) => {
        state.deletePermissionStatus.loading = true;
        state.deletePermissionStatus.error = false;
        state.deletePermissionStatus.success = false;
      })
      .addCase(deletePermission.fulfilled, (state) => {
        state.deletePermissionStatus.success = true;
        state.deletePermissionStatus.loading = false;
      })
      .addCase(deletePermission.rejected, (state) => {
        state.deletePermissionStatus.error = true;
        state.deletePermissionStatus.loading = false;
      });
  },
});

// Async API Hooks

/**
 * Fetch Permission List from API and save in redux store
 */
export const fetchPermissions = createAsyncThunk(
  'permissions/fetchPermissions',
  async (data: any, { rejectWithValue }) => {
    for (const key in data.params) {
      if (data.params[key] === '') {
        delete data.params[key];
      }
    }
    const response = await usePermissionApi().getPermissions(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Permission Details from API and save in redux store
 */
export const fetchPermissionDetails = createAsyncThunk(
  'permissions/fetchPermissionDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await usePermissionApi().getPermissionDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response.permission;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Save / Update Permission Details from API and update it in redux store.
 */
export const saveUpdatePermission = createAsyncThunk(
  'permissions/saveUpdatePermission',
  async (data: ISaveUpdatePermission, { rejectWithValue }) => {
    const id = data.id;
    const response = id
      ? await usePermissionApi().updatePermission(data.data, String(id))
      : await usePermissionApi().savePermission(data.data);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Delete Permission API and update it in redux store.
 */
export const deletePermission = createAsyncThunk(
  'permissions/deletePermission',
  async (id: string, { rejectWithValue }) => {
    const response = await usePermissionApi().deletePermissionData(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetPermissionDetails } = permissionSlice.actions;

export default permissionSlice.reducer;
