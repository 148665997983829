import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { PageOptions } from '../../constants/Common';
import { IRoleColumn, IRolePayload, IRoleRow } from 'src/datatypes/Role';
import _startCase from 'lodash/startCase';
import ActionButton from '../common/ActionButton';
import { canUserAccess } from 'src/helpers/common';

interface IProps {
  rolesList: IRolePayload['roles'];
}

const Listing = ({ rolesList }: IProps) => {
  //columns for Listing
  const columns: IRoleColumn[] = [
    {
      id: 'role_name',
      name: 'Role Name',
      selector: (row: IRoleRow) => row.name,
      sortable: true,
      cell: (row: IRoleRow) => _startCase(row.name),
    },
    {
      id: 'assigned_modules',
      name: 'Assigned Modules',
      selector: (row: IRoleRow) => row.permissions,
      sortable: false,
      compact: true,
      cell: (row: IRoleRow) => {
        const uniqueGroups = [
          ...new Set(row.permissions.map((permission) => permission.group)),
        ];

        const groupList =
          uniqueGroups.length > 0
            ? uniqueGroups.map((group) => _startCase(group)).join(', ')
            : 'N/A';
        return groupList;
      },
    },
    {
      id: 'edit_permissions',
      name: 'Edit Permissions',
      selector: (row: IRoleRow) => row.permissions,
      center: true,
      compact: true,
      cell: (row: IRoleRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <ActionButton
            action="edit"
            redirectTo={`/roles/${row?.id}/edit-permissions`}
            permissions={['update-role-permissions']}
          />
        </div>
      ),
      omit: !canUserAccess(['update-role-permissions']),
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={rolesList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              // paginationTotalRows={rolesList.data?.total}
              data={rolesList?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        {/* <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteQuestionDetail={deleteQuestionDetail}
        /> */}
      </div>
    </div>
  );
};

export default Listing;
