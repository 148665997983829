import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { PageOptions } from '../../constants/Common';
import _startCase from 'lodash/startCase';
import ActionButton from '../common/ActionButton';
import {
  IPermission,
  IPermissionColumn,
  IPermissionDetails,
  IPermissionPayload,
  IShowDeletePermissionModal,
} from 'src/datatypes/Permission';
import moment from 'moment';
import DeleteModal from './DeleteModal';
import { useEffect } from 'react';
import { deletePermission } from 'src/redux/slices/permissionSlice';
import { useAppDispatch } from 'src/redux/hooks';
import { canUserAccess } from 'src/helpers/common';

interface IProps {
  permissionsList: IPermissionPayload['permissions'];
  deletePermissionDetail: IPermissionPayload['deletePermissionStatus'];
  showDeleteModal: IShowDeletePermissionModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeletePermissionModal>
  >;
}

const Listing = ({
  permissionsList,
  deletePermissionDetail,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  //columns for Listing
  const columns: IPermissionColumn[] = [
    {
      id: 'permission_name',
      name: 'Permission Name',
      selector: (row: IPermission) => row.name,
      sortable: true,
      cell: (row: IPermission) => row.name,
    },
    {
      id: 'group_name',
      name: 'Group Name',
      selector: (row: IPermission) => row.name,
      sortable: true,
      compact: true,
      cell: (row: IPermission) => _startCase(row.group),
    },
    {
      id: 'created_at',
      name: 'Created Date',
      selector: (row: IPermission) => row.created_at,
      sortable: true,
      compact: true,
      cell: (row: IPermission) => {
        return (
          <span className="white-space-pre">
            {moment
              .utc(moment(row.created_at))
              .local()
              ?.format('MMM DD, YYYY[\r\n]h:mm a')}
          </span>
        );
      },
    },
    {
      id: 'action',
      name: 'Action',
      selector: (row: IPermission) => row.id,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: IPermission) => (
        <div>
          <ActionButton
            action="edit"
            redirectTo={`/permissions/${row.id}/edit`}
            permissions={['update-permission']}
          />
          <ActionButton
            action="delete"
            onClick={() => openDeleteModal(row)}
            permissions={['delete-permission']}
            className={'ml-2'}
          />
        </div>
      ),
      omit: !canUserAccess(['update-permission', 'delete-permission']),
    },
  ];

  // toggle delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeletePermissionModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected question
  const selectedQuestionId = (rows: IPermissionDetails[]) => {
    setShowDeleteModal((prev: IShowDeletePermissionModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete question
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((questionDetail: IPermissionDetails) => questionDetail.id)
      .join(',');
    dispatch(deletePermission(selectedIds));
  };

  // handle delete modal open
  const openDeleteModal = (row: IPermissionDetails) => {
    selectedQuestionId([row]);
    toggleDeleteModal();
  };

  // handle delete modal close on success delete
  useEffect(() => {
    if (deletePermissionDetail?.success) {
      setShowDeleteModal((prev: IShowDeletePermissionModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deletePermissionDetail?.success]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={permissionsList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              data={
                Array.isArray(permissionsList?.data)
                  ? permissionsList?.data
                  : []
              }
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteQuestionDetail={deletePermissionDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
