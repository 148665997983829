import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { canUserAccess } from 'src/helpers/common';

interface ActionButtonProps {
  action: 'view' | 'preview' | 'edit' | 'delete';
  redirectTo?: string;
  onClick?: () => void;
  className?: string;
  permissions: string[];
}

type ActionConfig = {
  [key in 'view' | 'preview' | 'edit' | 'delete']: {
    title: string;
    icon: string;
    class: string;
    component: React.ComponentType<any>; // dynamic components
  };
};

const ActionButton = ({
  action,
  redirectTo = '',
  onClick,
  className = '',
  permissions,
}: ActionButtonProps) => {
  const hasPermission = canUserAccess(permissions);

  // If the user does not have permission, return null early
  if (!hasPermission) return null;

  // Define button styles and icons based on the action
  const actionConfig: ActionConfig = {
    view: {
      title: 'View',
      icon: 'fa-eye',
      class: 'btn-secondary',
      component: Link,
    },
    preview: {
      title: 'Preview',
      icon: 'fa-address-card-o',
      class: 'btn-primary',
      component: Link,
    },
    edit: {
      title: 'Edit',
      icon: 'fa-pencil',
      class: 'btn-primary',
      component: Link,
    },
    delete: {
      title: 'Delete',
      icon: 'fa-trash',
      class: 'btn-danger',
      component: Button,
    },
  };

  const {
    title,
    icon,
    class: btnClass,
    component: ButtonComponent,
  } = actionConfig[action];

  // Common props for both Link and Button
  const commonProps = {
    title,
    className: `btn btn-sm ${btnClass} ${className}`,
    children: <i className={`fa ${icon}`} />,
  };

  // For 'delete' action, render Button (pass onClick)
  if (action === 'delete') {
    return <ButtonComponent {...commonProps} onClick={onClick} />;
  }

  // For Link (view, preview, edit), render Link with 'to' prop
  return <ButtonComponent {...commonProps} to={redirectTo || '#'} />;
};

export default ActionButton;
