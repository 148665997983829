import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
  IShowDeleteTestimonialModal,
  ITestimonialColumn,
  ITestimonialRow,
  ITestimonialPayload,
} from '../../datatypes/Testimonial';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { deleteTestimonialData } from '../../redux/slices/testimonialSlice';
import { PageOptions } from '../../constants/Common';
import ActionButton from '../common/ActionButton';
import { canUserAccess } from 'src/helpers/common';

interface IProps {
  testimonialList: ITestimonialPayload['testimonialList'];
  deleteTestimonialDetail: ITestimonialPayload['deleteTestimonialStatus'];
  showDeleteModal: IShowDeleteTestimonialModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteTestimonialModal>
  >;
}

const Listing = ({
  testimonialList,
  deleteTestimonialDetail,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  //siwtch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteTestimonialModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected testimonial
  const selectedTestimonialId = (rows: ITestimonialRow[]) => {
    setShowDeleteModal((prev: IShowDeleteTestimonialModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete testimonial
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((testimonialDetails: ITestimonialRow) => testimonialDetails.id)
      .join(',');
    dispatch(deleteTestimonialData(selectedIds));
  };

  // handle delete modal open
  const openDeleteModal = (row: ITestimonialRow) => {
    selectedTestimonialId([row]);
    toggleDeleteModal();
  };

  //columns for listing
  const columns: ITestimonialColumn[] = [
    {
      id: 'name',
      name: 'Name',
      selector: (row: ITestimonialRow) => row.name,
      sortable: true,
      width: '9.5rem',
    },
    {
      id: 'text',
      name: 'Text',
      selector: (row: ITestimonialRow) => row.testimonial_text,
      sortable: true,
      maxWidth: '28rem',
    },
    {
      id: 'location',
      name: 'Location',
      selector: (row: ITestimonialRow) => row.location,
      sortable: true,
      width: '9.5rem',
      cell: (row: ITestimonialRow) => row.location ?? 'N/A',
    },
    {
      id: 'date',
      name: 'Created On',
      selector: (row: ITestimonialRow) => row.date,
      sortable: true,
      width: '8.5rem',
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ITestimonialRow) => row.actions,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: ITestimonialRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <ActionButton
            action="view"
            redirectTo={`/testimonials/${row?.id}/view`}
            permissions={['view-testimonial-details']}
          />
          <ActionButton
            action="edit"
            redirectTo={`/testimonials/${row.id}/edit`}
            permissions={['update-testimonial']}
          />
          <ActionButton
            action="delete"
            onClick={() => openDeleteModal(row)}
            permissions={['delete-testimonial']}
          />
        </div>
      ),
      omit: !canUserAccess([
        'update-testimonial',
        'delete-testimonial',
        'view-testimonial-details',
      ]),
    },
  ];

  // handle delete modal close on success delete
  useEffect(() => {
    if (deleteTestimonialDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteTestimonialModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteTestimonialDetail?.success]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={testimonialList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={testimonialList.data?.total}
              data={testimonialList.data?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteTestimonialDetail={deleteTestimonialDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
