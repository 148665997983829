import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import {
  ICharityCategoryPayload,
  ICharityCategoryRow,
} from '../../datatypes/CharityCategory';

interface IProps {
  show: boolean;
  toggleDeleteModal: () => void;
  handleSubmit: () => void;
  selectedIdDetails: ICharityCategoryRow[];
  deleteCharityCategory: ICharityCategoryPayload['deleteCharityCategory'];
}

const DeleteModal = ({
  show,
  toggleDeleteModal,
  handleSubmit,
  selectedIdDetails,
  deleteCharityCategory,
}: IProps) => {
  return (
    <>
      <Modal show={show} onHide={toggleDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete charity category?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-testimonial">
            You will delete
            {selectedIdDetails?.length > 0 &&
              selectedIdDetails.map(
                (categoryDetail: ICharityCategoryRow, index: number) => (
                  <span className="mx-2">
                    {categoryDetail.name}
                    {selectedIdDetails?.length !== index + 1 && ', '}
                  </span>
                )
              )}
            charity category!
          </div>
          <div></div>
        </Modal.Body>
        <Modal.Footer>
          <FowButton variant="secondary" onClick={toggleDeleteModal}>
            Close
          </FowButton>
          <FowButton
            variant="danger"
            onClick={handleSubmit}
            isLoading={deleteCharityCategory?.loading}
          >
            Delete
          </FowButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
