import useAxiosService from '../services/axios.service';

const useRoleApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //get role list
  const getRoles = async () => {
    return await getUserHttpClient()
      .get('/roles')
      .then((response) => response?.data);
  };

  //get role by id
  const getRoleDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/roles/' + id)
      .then((response) => response?.data);
  };

  //update role permissions
  const saveUpdateRolePermissions = async (id: string, data: number[]) => {
    const formData = new FormData();

    Object.values(data).forEach((value) => {
      formData.append('permissions[]', value.toString());
    });

    return await getUserHttpClient()
      .post(`/roles/${id}/permissions`, formData)
      .then((response) => response?.data);
  };

  return {
    getRoles,
    getRoleDetails,
    saveUpdateRolePermissions,
  };
};

export default useRoleApi;
