import _startCase from 'lodash/startCase';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IPermissionRow } from 'src/datatypes/Permission';

interface PermissionsProps {
  permissions: IPermissionRow | null;
  formData: number[];
  guardName?: string;
  onCheckboxChange: (id: number, isChecked: boolean) => void;
  onSelectAllChange: (group: string, isChecked: boolean) => void;
}

const PermissionsCheckboxes: React.FC<PermissionsProps> = ({
  permissions,
  formData,
  guardName = 'api',
  onCheckboxChange,
  onSelectAllChange,
}) => {
  return (
    <div>
      {permissions && (
        <Row>
          {Object.keys(permissions).map((group) => {
            const groupPermissions = permissions[group];
            const hasApiGuard = groupPermissions.some(
              (permission) => permission.guard_name === guardName
            );

            const allSelected = groupPermissions
              .filter((permission) => permission.guard_name === guardName)
              .every((permission) => formData.includes(permission.id));

            if (!hasApiGuard) {
              return null;
            }

            return (
              <Col lg={6} key={group} className="permission-group">
                <Row className="align-items-center">
                  <Col lg={12}>
                    <div className="permission-group-header d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">{_startCase(group)}</h4>
                      <label className="mb-0">
                        <input
                          type="checkbox"
                          checked={allSelected}
                          onChange={(e) =>
                            onSelectAllChange(group, e.target.checked)
                          }
                        />
                        Select All
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3 mt-3">
                  <Col lg={12}>
                    <div className="ml-4">
                      {groupPermissions.map((permission) => {
                        if (permission.guard_name === guardName) {
                          return (
                            <div key={permission.id}>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={formData.includes(permission.id)}
                                  onChange={(e) =>
                                    onCheckboxChange(
                                      permission.id,
                                      e.target.checked
                                    )
                                  }
                                  value={permission.id}
                                />
                                {_startCase(permission.name)}
                              </label>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default PermissionsCheckboxes;
