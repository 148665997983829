import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import {
  IShowDeleteMetaModal,
  IMetaColumn,
  IMetaRow,
  IMetaPayload,
} from '../../datatypes/Meta';
import { deleteMeta } from '../../redux/slices/metaSlice';
import { PageOptions } from '../../constants/Common';
import ActionButton from '../common/ActionButton';
import { canUserAccess } from 'src/helpers/common';

interface IProps {
  metaList: IMetaPayload['metaList'];
  deleteMetaDetail: IMetaPayload['deleteMetaStatus'];
  showDeleteModal: IShowDeleteMetaModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteMetaModal>
  >;
}

const Listing = ({
  metaList,
  deleteMetaDetail,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  //switch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteMetaModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected meta data page
  const selectedMetaId = (rows: IMetaRow[]) => {
    setShowDeleteModal((prev: IShowDeleteMetaModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  //handle delete meta
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((metaDetails: IMetaRow) => metaDetails.id)
      .join(',');
    dispatch(deleteMeta(selectedIds));
  };

  //handle delete modal open
  const openDeleteModal = (row: IMetaRow) => {
    selectedMetaId([row]);
    toggleDeleteModal();
  };

  //   page_title: string;
  //   page_code: string;
  //columsn for listing
  const columns: IMetaColumn[] = [
    {
      name: 'Page Code',
      selector: (row: IMetaRow) => row.page_code,
      sortable: true,
      width: '15rem',
    },
    {
      name: 'Page Title',
      selector: (row: IMetaRow) => row.page_title,
      sortable: true,
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IMetaRow) => row.actions,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: IMetaRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <ActionButton
            action="view"
            redirectTo={`/meta-tags/${row?.id}/view`}
            permissions={['view-meta-tag-details']}
          />
          <ActionButton
            action="edit"
            redirectTo={`/meta-tags/${row.id}/edit`}
            permissions={['update-meta-tag']}
          />
          <ActionButton
            action="delete"
            onClick={() => openDeleteModal(row)}
            permissions={['delete-meta-tag']}
          />
        </div>
      ),
      omit: !canUserAccess([
        'view-meta-tag-details',
        'update-meta-tag',
        'delete-meta-tag',
      ]),
    },
  ];

  useEffect(() => {
    if (deleteMetaDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteMetaModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteMetaDetail?.success]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={metaList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={metaList.data?.total}
              data={metaList.data?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteMetaDetail={deleteMetaDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
