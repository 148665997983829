import moment from 'moment';
import Navigations from '../../src/components/containers/adminNav';
import routes from '../../src/components/AdminRoutes';

export const formatDate = (
  dateString: string,
  currentFormat: string = 'DD/MM/YYYY'
) => {
  if (!dateString) {
    return ''; // Handle empty date case
  }

  const parsedDate = moment(dateString, currentFormat, true);

  if (!parsedDate.isValid()) {
    return ''; // Handle invalid date case
  }

  return parsedDate.format('YYYY-MM-DD');
};

export const formatLabel = (label: string) => {
  switch (label) {
    case 'creditcard':
      return 'Credit Card';
    case 'pending':
      return 'Pending';
    default:
      return label;
  }
};

export const statusBadgeBackground = (name: string) => {
  switch (name) {
    case 'pending':
      return 'badge badge-pill badge-primary py-1';
    default:
      return 'badge badge-pill badge-secondary py-1';
  }
};

// functions to help data manipulation and formatting

export function deleteEmptyKeys(obj: any) {
  if (typeof obj === 'object') {
    for (let key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        (obj[key] === null || obj[key] === undefined || obj[key] === '')
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
}

export const isMinor = (date: string) => {
  return moment().diff(date, 'years', false) < 18;
};

export const formatNumber = (val: number, unit: string) => {
  if (unit === '$') {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(val);
  }
  return `${Number(val).toFixed(2)}%`;
};

export const downloadCSV = ({
  data,
  fileName,
}: {
  data: any;
  fileName: string;
}) => {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const convertToCSV = (data: any) => {
  const header = Object.keys(data[0]).join(',');
  const rows = data.map((row: any) => {
    return Object.values(row)
      .map((value: any) => {
        // Escape quotes and commas
        const escapedValue = `${value}`.replace(/"/g, '""');
        return `"${escapedValue}"`;
      })
      .join(',');
  });
  return [header, '', ...rows].join('\n'); // Combine header and rows
};

export const canUserAccess = (permissions: string[] | undefined) => {
  const adminUser = JSON.parse(localStorage.getItem('admin_user') ?? '');
  // Check if the user is an admin
  if (adminUser.roles && adminUser.roles.includes('admin')) {
    return true;
  }

  // Check if the user has any of the specified permissions
  if (permissions) {
    const hasPermissionAccess = adminUser.permissions && permissions.some(permission => adminUser.permissions.includes(permission));
    return hasPermissionAccess;
  }
  return false;
};

export const getRedirectRoute = (route: string = '') => {
  // If a specific route is passed, check for its permissions
  if (route) {
    const routeMatch = routes.find(item => 
      item.path === route && item.permissions && canUserAccess(item.permissions)
    );
    
    if (routeMatch) {
      return routeMatch.path;
    }
  }

  const routeToRedirect = Navigations.find(item =>
    item.permissions && canUserAccess(item.permissions) && item.to
  );

  return routeToRedirect?.to || null;
};