import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IRouteParams } from '../../datatypes/CommonTypes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import _forEach from 'lodash/forEach';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import TextInput from '../../components/common/TextInput';
import { permissionValidations } from '../../constants/Permission';
import { Link } from 'react-router-dom';
import {
  IPermissionDetails,
  IPermissionPayload,
} from 'src/datatypes/Permission';
import {
  guardNameOptions,
  templatePermissionDetails,
} from 'src/constants/Permission';
import {
  fetchPermissionDetails,
  resetPermissionDetails,
  saveUpdatePermission,
} from 'src/redux/slices/permissionSlice';
import SelectInput from 'src/components/common/SelectInput';

const PermissionDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id }: IRouteParams = useParams();
  const location = useLocation();

  const { permissionDetails, saveUpdateStatus } =
    useAppSelector<IPermissionPayload>((state) => state.permissions);

  const [pageType, setPageType] = useState<'new' | 'edit' | 'view'>('new');

  const [formData, setFormData] = useState<IPermissionDetails>({
    ...templatePermissionDetails,
  });

  const [errors, setErrors] = useState<IPermissionDetails>(
    templatePermissionDetails
  );

  const validations = useMemo(() => {
    return {
      ...permissionValidations,
    };
  }, []);

  //handle normal input
  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value: string | number;
    let error: string | undefined | null;

    value = e.target.value;

    const fieldName = name;

    error = ValidateField(fieldName, value, validations[name]) ?? '';

    //set errors
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    //set form data
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  //check if permission - form has any error
  const hadFormError = () => {
    let hasErrors = false;
    const newFormErrors = JSON.parse(JSON.stringify(errors));
    _forEach(formData, (value, key) => {
      const error = ValidateField(key, value, validations[key]);
      if (error) {
        newFormErrors[key] = error;
        hasErrors = true;
      } else {
        newFormErrors[key] = '';
      }
    });
    setErrors(newFormErrors);
    return hasErrors;
  };

  //handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hadFormError()) {
      let payload = { data: { ...formData }, id: id };
      dispatch(saveUpdatePermission(payload));
    }
  };

  // function to get permission details
  const getPermissionDetails = async () => {
    if (id && id !== 'new') {
      dispatch(fetchPermissionDetails(id));
      const containsView = location.pathname.includes('view');
      if (containsView) {
        setPageType('view');
      } else {
        setPageType('edit');
      }
    }
  };

  //on page load
  useEffect(() => {
    setFormData({
      ...templatePermissionDetails,
    });
    setErrors({
      ...templatePermissionDetails,
    });
    getPermissionDetails();
    // eslint-disable-next-line
  }, []);

  // on successful update / save
  useEffect(() => {
    if (saveUpdateStatus?.success) {
      toastSuccessMessage(
        `Permission ${
          pageType === 'edit' ? 'updated' : 'created'
        } successfully!`
      );
      dispatch(resetPermissionDetails());
      navigate('/permissions');
    }
    // eslint-disable-next-line
  }, [saveUpdateStatus]);

  //on successful permission details fetch
  useEffect(() => {
    if (permissionDetails?.success && permissionDetails?.data) {
      const newFormData = JSON.parse(
        JSON.stringify({
          ...permissionDetails.data,
        })
      );

      setFormData(newFormData);

      setErrors({
        ...templatePermissionDetails,
      });
    }
  }, [permissionDetails]);

  return (
    <div className="permission-details profile-details">
      <PageTitle
        heading={
          pageType === 'new'
            ? 'New Permission'
            : `Permission : ${permissionDetails?.data?.name ?? ''}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/permissions')}
      />
      {permissionDetails.loading ? (
        <Loader isFull />
      ) : !!permissionDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={6} md={12}>
                  <TextInput
                    controlId="name"
                    label="Permission Name"
                    className="form-field"
                    placeholder="Permission Name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.name}
                    isRequired={true}
                    isDisabled={pageType === 'view'}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <TextInput
                    controlId="group"
                    label="Permission Group"
                    className="form-field"
                    placeholder="Permission Group"
                    name="group"
                    value={formData.group}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.group}
                    isRequired={true}
                    isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={6} md={12}>
                  <SelectInput
                    name="guard_name"
                    placeholder="Select Guard Name"
                    value={formData.guard_name}
                    optionLabel="label"
                    optionValue="value"
                    options={guardNameOptions}
                    handleSelectChange={(e: any) => handleInputs(e)}
                    label="Guard Name"
                    isRequired={false}
                    isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  {pageType !== 'view' && (
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ marginRight: '10px' }}
                      disabled={saveUpdateStatus?.loading}
                    >
                      {pageType === 'new' ? 'Save' : 'Update'}
                    </Button>
                  )}
                  <Link
                    className={`button-link secondary ${
                      saveUpdateStatus?.loading ? 'disabled' : ''
                    }`}
                    to="/permissions"
                  >
                    Cancel
                  </Link>
                </Col>
              </Row>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Page Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default PermissionDetails;
