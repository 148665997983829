import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Listing from '../../components/permissions/Listing';
import {
  fetchPermissions,
  resetPermissionDetails,
} from 'src/redux/slices/permissionSlice';
import {
  IPermissionPayload,
  IShowDeletePermissionModal,
} from 'src/datatypes/Permission';
import { canUserAccess } from 'src/helpers/common';
import { useNavigate } from 'react-router-dom';
import { toastSuccessMessage } from 'src/components/common/ToastMessage';

const PermissionListing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { permissions, deletePermissionStatus } =
    useAppSelector<IPermissionPayload>((state) => state.permissions);
  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeletePermissionModal>({
      show: false,
      idDetails: [],
    });

  const getPermissionsListData = () => {
    dispatch(fetchPermissions({}));
  };

  useEffect(() => {
    getPermissionsListData();
    // eslint-disable-next-line
  }, []);

  //handle successful delete api call and modal closing
  useEffect(() => {
    if (deletePermissionStatus.success) {
      toastSuccessMessage('Permission deleted successfully!');
      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetPermissionDetails());
      getPermissionsListData();
    }
    // eslint-disable-next-line
  }, [deletePermissionStatus]);

  return (
    <div className="permission-listing">
      <PageTitle
        heading="Permissions"
        buttonName="New"
        buttonClick={() => {
          dispatch(resetPermissionDetails());
          navigate('/permissions/new');
        }}
        showButton={canUserAccess(['create-permission'])}
      />
      <div className="permission-listing-content">
        <Listing
          permissionsList={permissions}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deletePermissionDetail={deletePermissionStatus}
        />
      </div>
    </div>
  );
};

export default PermissionListing;
