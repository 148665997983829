import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ValidateField } from '../../helpers/ValidatorHelper';
import { searchTerminologyField } from '../../constants/Terminology';
import {
  IShowDeleteTerminologyModal,
  ITerminologyPayload,
} from '../../datatypes/Terminology';
import {
  fetchTerminologies,
  resetTerminologyData,
} from '../../redux/slices/terminologySlice';
import Listing from '../../components/terminologies/Listing';
import TerminologySearch from '../../components/terminologies/TerminologySearch';
import { canUserAccess } from 'src/helpers/common';

const TerminologiesListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [terminologySearch, setTerminologySearch] = useState(
    searchTerminologyField
  );

  const [errors, setErrors] = useState(searchTerminologyField);

  const [showAdvancedSearch, setShowAdvancedSearch] = useState({
    show: false,
    filterApplied: false,
  });

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeleteTerminologyModal>({
      show: false,
      idDetails: [],
    });

  const { terminologyList, deleteTerminologyStatus } =
    useAppSelector<ITerminologyPayload>((state) => state.terminologies);

  //function to toggle advanced search
  const toggleAdvancedFilter = () => {
    setShowAdvancedSearch({
      ...showAdvancedSearch,
      show: !showAdvancedSearch.show,
    });
  };

  //function to toggle filter applied
  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvancedSearch({
      ...showAdvancedSearch,
      filterApplied: isApplied,
    });
  };

  //function to handle search input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTerminologySearch((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const fieldName = name === 'alternative_term' ? 'Alternative Term' : name;
    //check for errors
    const error =
      value === ''
        ? null
        : ValidateField(fieldName, value, String(errors[name]));

    //set errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error || '',
    }));
  };

  //function to validate search on submit
  const validateForm = () => {
    const hasValues = Object.keys(terminologySearch).some(
      (key) => terminologySearch[key] !== ''
    );
    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');

    return !hasValues || hasErrors;
  };

  //function to handle search button click
  const onTerminologySearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      const payload = {
        ...terminologySearch,
      };
      dispatch(fetchTerminologies({ params: payload }));
      toggleFilterApplied(true);
    }
  };

  // function to handle reset button click
  const resetTerminologySearch = () => {
    dispatch(fetchTerminologies({ params: {} }));
    setTerminologySearch(searchTerminologyField);
    toggleFilterApplied(false);
  };

  useEffect(() => {
    if (!terminologyList.loading) {
      dispatch(fetchTerminologies({ params: {} }));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deleteTerminologyStatus.success) {
      toastSuccessMessage('Terminology Deleted Successfully');
      setShowDeleteModal({
        show: false,
        idDetails: [],
      });

      dispatch(resetTerminologyData());
      dispatch(fetchTerminologies({ params: {} }));
    }
    // eslint-disable-next-line
  }, [deleteTerminologyStatus]);

  return (
    <div className="terminologies-templates">
      <PageTitle
        heading="Terminologies"
        buttonName="Add New"
        buttonClick={async () => {
          dispatch(resetTerminologyData());
          navigate('/terminologies/new');
        }}
        showButton={canUserAccess(['create-terminology'])}
      />
      <div className="control-top-button mb-2">
        <Button
          variant="primary"
          className="mr-2"
          onClick={() => toggleAdvancedFilter()}
        >
          Advanced Search
        </Button>
      </div>
      <div className="terminologies-content">
        {showAdvancedSearch.show && (
          <TerminologySearch
            errors={errors}
            handleInputChange={handleInputChange}
            terminologySearch={terminologySearch}
            onTerminologySearch={onTerminologySearch}
            resetTerminologySearch={resetTerminologySearch}
            isFilterApplied={showAdvancedSearch.filterApplied}
          />
        )}
        <Listing
          terminologyList={terminologyList}
          showDeleteTerminologyModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deleteTerminologyDetail={deleteTerminologyStatus}
        />
      </div>
    </div>
  );
};

export default TerminologiesListing;
