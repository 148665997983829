import { Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import InvoiceDashboardListing from '../invoices/InvoiceDashboardListing';
import MissingWillSearchDashboardListing from '../missing-will-searches/MissingWillSearchDashboardListing';
import { IDashboardPayload } from '../../datatypes/Dashboard';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchDashboardData } from '../../redux/slices/dashboardSlice';
import WillCreatorDashboardListing from '../will-creators/WillCreatorDashboardListing';
import Loader from '../../components/common/Loader';
import { canUserAccess } from 'src/helpers/common';

const Dashboard = () => {
  const dispatch = useAppDispatch();

  const { dashboardData } = useAppSelector<IDashboardPayload>(
    (state) => state.dashboard
  );

  const getDashboardData = () => {
    dispatch(fetchDashboardData());
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line
  }, []);

  return dashboardData.loading ? (
    <Loader isFull />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Row>
        {canUserAccess(['view-invoices']) && (
          <Col lg={6}>
            <InvoiceDashboardListing
              invoiceData={dashboardData.data.invoiceData}
              isLoading={dashboardData.loading}
            />
          </Col>
        )}
        {canUserAccess(['view-missing-will-search']) && (
          <Col lg={6}>
            <MissingWillSearchDashboardListing
              missingWillData={dashboardData.data.missingWillData}
              isLoading={dashboardData.loading}
            />
          </Col>
        )}
        {canUserAccess(['view-will-creators']) && (
          <Col lg={6}>
            <WillCreatorDashboardListing
              willCreatorsData={dashboardData.data.willCreatorsData}
              isLoading={dashboardData.loading}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Dashboard;
