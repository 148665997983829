import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import {
  IWillCreatorPayload,
  IWillCreatorRow,
} from '../../datatypes/WillCreator';
interface IProps {
  show: boolean;
  toggleDeleteModal: () => void;
  handleSubmit: () => void;
  selectedIdDetails: IWillCreatorRow[];
  deleteWillStatusDetail: IWillCreatorPayload['deleteWillCreatorStatus'];
}

const DeleteModal = ({
  show,
  toggleDeleteModal,
  handleSubmit,
  selectedIdDetails,
  deleteWillStatusDetail,
}: IProps) => {
  return (
    <>
      <Modal show={show} onHide={toggleDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete will?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-will">
            You will delete for{' '}
            {selectedIdDetails?.length > 0 &&
              selectedIdDetails.map(
                (willStatusDetail: IWillCreatorRow, index: number) => (
                  <span>
                    {willStatusDetail.first_name} {willStatusDetail.last_name}
                    {selectedIdDetails?.length !== index + 1 && ', '}
                  </span>
                )
              )}
            {''} will!
          </div>
          <div></div>
        </Modal.Body>
        <Modal.Footer>
          <FowButton variant="secondary" onClick={toggleDeleteModal}>
            Close
          </FowButton>
          <FowButton
            variant="danger"
            onClick={handleSubmit}
            isLoading={deleteWillStatusDetail?.loading}
          >
            Delete
          </FowButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
