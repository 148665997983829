import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import { ITemplateDetailRow, ITemplatePayload } from '../../datatypes/Template';

interface IProps {
  show: boolean;
  toggleDeleteModal: () => void;
  handleSubmit: () => void;
  selectedIdDetails: ITemplateDetailRow[];
  deleteTemplateDetail: ITemplatePayload['deleteTemplateStatus'];
}
const DeleteModal = ({
  show,
  toggleDeleteModal,
  handleSubmit,
  selectedIdDetails,
  deleteTemplateDetail,
}: IProps) => {
  return (
    <>
      <Modal show={show} onHide={toggleDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete template?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-template">
            You will delete
            {selectedIdDetails?.length > 0 &&
              selectedIdDetails.map(
                (templateDetail: ITemplateDetailRow, index: number) => (
                  <span className="mx-2">
                    {templateDetail.title}
                    {selectedIdDetails?.length !== index + 1 && ', '}
                  </span>
                )
              )}
            template!
          </div>
          <div></div>
        </Modal.Body>
        <Modal.Footer>
          <FowButton variant="secondary" onClick={toggleDeleteModal}>
            Close
          </FowButton>
          <FowButton
            variant="danger"
            onClick={handleSubmit}
            isLoading={deleteTemplateDetail?.loading}
          >
            Delete
          </FowButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
