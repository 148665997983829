import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Dtspinner, dtCustomStyle } from '../../helpers/DataTableHelper';
import { PageOptions } from '../../constants/Common';
import {
  ICharityPartnershipsColumn,
  ICharityPartnershipsPayload,
  ICharityPartnershipsRow,
} from '../../datatypes/CharityPartnership';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ActionButton from '../common/ActionButton';

interface IProps {
  charityPartnershipList: ICharityPartnershipsPayload['charityPartnershipList'];
}

const Listing = ({ charityPartnershipList }: IProps) => {
  const columns: ICharityPartnershipsColumn[] = [
    {
      name: 'ID',
      selector: (_: ICharityPartnershipsRow, index?: number) =>
        typeof index === 'number' ? index + 1 : '',
      sortable: true,
      width: '5.5rem',
    },
    {
      name: 'Charity Name',
      selector: (row: ICharityPartnershipsRow) => row.charity_details.name,
      sortable: true,
    },
    {
      name: 'Partnership Date',
      selector: (row: ICharityPartnershipsRow) => row.partnership_date,
      sortable: true,
      width: '15.5rem',
      cell: (row: ICharityPartnershipsRow) => (
        <span className="white-space-pre">
          {moment
            .utc(new Date(row['partnership_date']))
            .local()
            .format('MMM DD, YYYY')}
        </span>
      ),
    },
    {
      name: 'Status',
      selector: (row: ICharityPartnershipsRow) => row.status,
      sortable: true,
      width: '7.5rem',
      center: true,
      cell: (row: ICharityPartnershipsRow) => {
        switch (row?.status) {
          case 'active':
            return <span className="badge badge-success">Active</span>;
          case 'inactive':
            return <span className="badge badge-secondary">Inactive</span>;
          default:
            return <span className="badge badge-warning">Archive</span>;
        }
      },
    },
    {
      name: 'Created At',
      selector: (row: ICharityPartnershipsRow) => row.created_at,
      sortable: true,
      width: '10.5rem',
      cell: (row: ICharityPartnershipsRow) => (
        <span className="white-space-pre">
          {moment
            .utc(new Date(row['created_at']))
            .local()
            .format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ICharityPartnershipsRow) => row.actions,
      center: true,
      compact: true,
      width: '9.5rem',
      cell: (row: ICharityPartnershipsRow) => (
        <div>
          <ActionButton
            action="edit"
            redirectTo={`/partnerships/${row.id}/edit`}
            permissions={['update-partnership']}
          />
          <Link
            title="View"
            className="btn btn-secondary btn-sm ml-2"
            to={`${process.env.REACT_APP_WILL_CREATION_URL}/partnership/${row.charity_details?.slug}`}
            key="view"
            target="_blank"
          >
            <i className="fa fa-external-link"></i>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={charityPartnershipList.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={charityPartnershipList.data?.total}
              data={charityPartnershipList.data?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Listing;
