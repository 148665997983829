import { IPermissionDetails } from 'src/datatypes/Permission';
import useAxiosService from '../services/axios.service';

const usePermissionApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //get permissions list
  const getPermissions = async (params?: any) => {
    return await getUserHttpClient()
      .get('/permissions', { params: params ? params : {} })
      .then((response) => response?.data);
  };

  //get permission by id
  const getPermissionDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/permissions/' + id)
      .then((response) => response?.data);
  };

  //save permission
  const savePermission = async (data: IPermissionDetails) => {
    return await getUserHttpClient()
      .post('/permissions', data)
      .then((response) => response);
  };

  // update permission
  const updatePermission = async (data: IPermissionDetails, id: string) => {
    return await getUserHttpClient()
      .put(`/permissions/${id}`, data)
      .then((response) => response);
  };

  //delete permission by id
  const deletePermissionData = async (id: string) => {
    return await getUserHttpClient()
      .delete(`/permissions/${id}`)
      .then((response) => response?.data);
  };

  return {
    getPermissions,
    getPermissionDetails,
    savePermission,
    updatePermission,
    deletePermissionData,
  };
};

export default usePermissionApi;
