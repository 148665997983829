import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Dtspinner, dtCustomStyle } from '../../helpers/DataTableHelper';
import {
  IShowDeleteCharityModal,
  ICharityColumn,
  ICharityRow,
  ICharityPayload,
} from '../../datatypes/Charity';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { deleteCharityDetails } from '../../redux/slices/charitySlice';
import { PageOptions } from '../../constants/Common';
import ActionButton from '../common/ActionButton';
import { canUserAccess } from 'src/helpers/common';

interface IProps {
  charityList: ICharityPayload['charityList'];
  deleteCharityDetail: ICharityPayload['deleteCharity'];
  showDeleteModal: IShowDeleteCharityModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteCharityModal>
  >;
}

const Listing = ({
  charityList,
  deleteCharityDetail,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  // switch function for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteCharityModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  const selectedCharityId = (rows: ICharityRow[]) => {
    setShowDeleteModal((prev: IShowDeleteCharityModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // function to handle delete
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((charityDetail: ICharityRow) => charityDetail.id)
      .join(',');
    dispatch(deleteCharityDetails(selectedIds));
  };

  const openDeleteModal = (row: ICharityRow) => {
    selectedCharityId([row]);
    toggleDeleteModal();
  };

  useEffect(() => {
    if (deleteCharityDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteCharityModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteCharityDetail?.success]);

  const columns: ICharityColumn[] = [
    {
      name: 'Name',
      selector: (row: ICharityRow) => row.name,
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row: ICharityRow) => row.category_name,
      sortable: true,
      width: '10.5rem',
    },
    {
      name: 'Status',
      selector: (row: ICharityRow) => row.status,
      sortable: true,
      width: '6.5rem',
      center: true,
      cell: (row: ICharityRow) => {
        switch (row?.status) {
          case 'active':
            return <span className="badge badge-success">Active</span>;
          case 'inactive':
            return <span className="badge badge-secondary">Inactive</span>;
          default:
            return <span className="badge badge-warning">Archive</span>;
        }
      },
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ICharityRow) => row.actions,
      center: true,
      compact: true,
      width: '9.5rem',
      cell: (row: ICharityRow) => (
        <div>
          <ActionButton
            action="edit"
            redirectTo={`/charities/${row.id}/edit`}
            permissions={['update-charity']}
          />
          <ActionButton
            action="view"
            redirectTo={`/charities/${row.id}/view`}
            permissions={['view-charity-details']}
            className={'ml-2'}
          />
          <ActionButton
            action="delete"
            onClick={() => openDeleteModal(row)}
            permissions={['delete-charity']}
            className={'ml-2'}
          />
        </div>
      ),
      omit: !canUserAccess([
        'update-charity',
        'view-charity-details',
        'delete-charity',
      ]),
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-12">
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h2>Charities</h2>
          </Card.Header>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={charityList.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={charityList.data?.total}
              data={charityList.data?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteCharityDetail={deleteCharityDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
