import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { deletePageDetails } from '../../redux/slices/pageSlice';
import { PageOptions } from '../../constants/Common';
import {
  IShowDeletePageModal,
  IPageRow,
  IPageColumn,
  IPagePayload,
} from '../../datatypes/Page';
import { deleteTestimonialData } from '../../redux/slices/testimonialSlice';
import ActionButton from '../common/ActionButton';
import { canUserAccess } from 'src/helpers/common';

interface IProps {
  pageList: IPagePayload['pagesList'];
  deletePageDetail: IPagePayload['deletePageStatus'];
  showDeleteModal: IShowDeletePageModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeletePageModal>
  >;
}

const Listing = ({
  pageList,
  deletePageDetail,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  //switch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeletePageModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected pages
  const selectedPagesId = (rows: IPageRow[]) => {
    setShowDeleteModal((prev: IShowDeletePageModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((pageDetail: IPageRow) => pageDetail.id)
      .join(',');
    dispatch(deletePageDetails(selectedIds));
  };

  // handle delete modal open
  const openDeleteModal = (row: IPageRow) => {
    selectedPagesId([row]);
    toggleDeleteModal();
  };

  const columns: IPageColumn[] = [
    {
      id: 'title',
      name: 'Title',
      selector: (row: IPageRow) => row.title,
      sortable: true,
    },
    {
      id: 'slug',
      name: 'Slug',
      selector: (row: IPageRow) => row.slug,
      sortable: true,
    },
    {
      id: 'status',
      name: 'Status',
      selector: (row: IPageRow) => row.status,
      center: true,
      compact: true,
      cell: (row: IPageRow) => {
        switch (row?.status) {
          case 'active':
            return <span className="badge badge-success">Active</span>;
          case 'inactive':
            return <span className="badge badge-secondary">Inactive</span>;
          default:
            return <span className="badge badge-warning">Archive</span>;
        }
      },
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IPageRow) => row.actions,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: IPageRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <ActionButton
            action="view"
            redirectTo={`/pages/${row?.id}/view`}
            permissions={['view-page-details']}
          />
          <ActionButton
            action="edit"
            redirectTo={`/pages/${row.id}/edit`}
            permissions={['update-page']}
          />
          <ActionButton
            action="delete"
            onClick={() => openDeleteModal(row)}
            permissions={['delete-page']}
          />
        </div>
      ),
      omit: !canUserAccess(['view-page-details', 'update-page', 'delete-page']),
    },
  ];

  useEffect(() => {
    if (deletePageDetail?.success) {
      setShowDeleteModal((prev: IShowDeletePageModal) => ({
        ...prev,
        show: false,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteTestimonialData]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={pageList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={pageList.data?.total}
              data={pageList.data?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deletePageDetail={deletePageDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
