import React from 'react';
import DataTable from 'react-data-table-component';
import { Card } from 'react-bootstrap';
import { dtCustomStyle, Dtspinner } from '../../helpers/DataTableHelper';
import { IWillPagination } from '../../datatypes/MisssingWillSearch';
import {
  IObituaryPagination,
  IObituaryPayload,
  IObituaryDetailRow,
  IObituaryDetailColumn,
} from '../../datatypes/Obituary';
import { useAppDispatch } from '../../redux/hooks';
import { deleteDeathNotice } from '../../redux/slices/deathNoticeSlice';
import { PageOptions } from '../../constants/Common';
import moment from 'moment';
import { ISortParams } from 'src/datatypes/CommonTypes';
import ActionButton from '../common/ActionButton';
import { canUserAccess } from 'src/helpers/common';

interface IProps {
  obituaryList: IObituaryPayload['obituaryList'];
  setPagination: React.Dispatch<React.SetStateAction<IObituaryPagination>>;
  setSort: React.Dispatch<React.SetStateAction<ISortParams>>;
}

const ObituaryList = ({ obituaryList, setPagination, setSort }: IProps) => {
  const dispatch = useAppDispatch();
  const columns: IObituaryDetailColumn[] = [
    {
      name: 'Name',
      selector: (row: IObituaryDetailRow) => row['name'],
      sortable: true,
      sortField: 'first_name',
      cell: (row: IObituaryDetailRow) =>
        `${row.first_name} ${row.middle_name ? row.middle_name : ''} ${
          row.last_name
        }`.trim(),
    },
    {
      name: 'Location',
      selector: (row: IObituaryDetailRow) => row['location'],
      sortField: 'city',
      sortable: true,
    },
    {
      name: 'Birth Date',
      selector: (row: IObituaryDetailRow) => row['birthdate'],
      sortField: 'birthdate',
      sortable: true,
      right: true,
    },
    {
      name: 'Death Date',
      selector: (row: IObituaryDetailRow) => row['deathdate'],
      sortField: 'deathdate',
      sortable: true,
      right: true,
    },
    {
      name: 'Created On',
      selector: (row: IObituaryDetailRow) => row['posted_date'],
      sortField: 'created_at',
      sortable: true,
      right: true,
      cell: (row: IObituaryDetailRow) => (
        <span className="white-space-pre">
          {moment
            .utc(moment(row['posted_date']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
    },
    {
      name: 'Actions',
      selector: (row: IObituaryDetailRow) => row['actions'],
      cell: (row: IObituaryDetailRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <ActionButton
            action="view"
            redirectTo={`/obituaries/${row?.id}/view`}
            permissions={['view-obituary-details']}
          />
          <ActionButton
            action="edit"
            redirectTo={`/obituaries/${row.id}/edit`}
            permissions={['update-obituary']}
          />
          <ActionButton
            action="delete"
            onClick={() => deleteDeathNoticeDetail(row)}
            permissions={['delete-death-notice']}
            className="ml-1"
          />
        </div>
      ),
      omit: !canUserAccess([
        'view-obituary-details',
        'update-obituary',
        'delete-death-notice',
      ]),
      center: true,
    },
  ];

  const deleteDeathNoticeDetail = async (row: IObituaryDetailRow) => {
    dispatch(deleteDeathNotice(String(row.id)));
  };

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h2>Obituaries List</h2>
          </Card.Header>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={obituaryList.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              columns={columns}
              paginationTotalRows={obituaryList?.data?.total}
              onChangeRowsPerPage={(new_per_page: number) =>
                setPagination((prev: IWillPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }))
              }
              data={obituaryList.data?.data}
              paginationDefaultPage={1}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) =>
                setPagination((prev: IWillPagination) => ({
                  ...prev,
                  page: page,
                }))
              }
              onSort={(column, direction) => {
                setSort({ orderBy: column.sortField, order: direction });
              }}
              sortServer
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ObituaryList;
