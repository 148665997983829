import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IRouteParams } from '../../datatypes/CommonTypes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import {
  fetchRoleDetails,
  resetRoleDetails,
  saveUpdateRolePermissions,
} from 'src/redux/slices/roleSlice';
import { fetchPermissions } from 'src/redux/slices/permissionSlice';
import { IPermissionPayload } from 'src/datatypes/Permission';
import { IRolePayload } from 'src/datatypes/Role';
import PermissionsCheckboxes from 'src/components/permissions/PermissionsCheckboxes';
import { Link } from 'react-router-dom';
import _startCase from 'lodash/startCase';

const RoleDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id }: IRouteParams = useParams();

  const { permissions } = useAppSelector<IPermissionPayload>(
    (state) => state.permissions
  );

  const { roleDetails, saveUpdateStatus } = useAppSelector<IRolePayload>(
    (state) => state.roles
  );

  const [formData, setFormData] = useState<number[]>([]);

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    setFormData((prevFormData) => {
      if (isChecked) {
        return [...prevFormData, id]; // Add ID if checked
      } else {
        return prevFormData.filter((selectedId) => selectedId !== id); // Remove ID if unchecked
      }
    });
  };

  const handleSelectAllChange = (group: string, isChecked: boolean) => {
    if (permissions?.data) {
      if (!Array.isArray(permissions.data)) {
        const groupPermissions = permissions?.data[group];
        const permissionIds: number[] = groupPermissions
          .map((permission) => {
            if (permission.guard_name === roleDetails?.data?.guard_name) {
              return permission.id;
            }
            return null;
          })
          .filter((id): id is number => id !== null);

        if (isChecked && permissionIds.length > 0) {
          setFormData((prevFormData) => [
            ...prevFormData,
            ...permissionIds.filter((id: number) => !prevFormData.includes(id)), // Add missing IDs
          ]);
        } else {
          setFormData((prevFormData) =>
            prevFormData.filter((id) => !permissionIds.includes(id))
          ); // Remove group IDs
        }
      }
    }
  };

  // function to get role details
  const getRoleDetails = async () => {
    if (id && id !== 'new') {
      dispatch(fetchRoleDetails(id));
      dispatch(fetchPermissions({ groupBy: true }));
    }
  };

  // on successful update / save
  useEffect(() => {
    if (saveUpdateStatus?.success) {
      toastSuccessMessage(`Role Permissions updated successfully!`);
      dispatch(resetRoleDetails());
      navigate('/roles');
    }
    // eslint-disable-next-line
  }, [saveUpdateStatus]);

  //on page load
  useEffect(() => {
    getRoleDetails();
    // eslint-disable-next-line
  }, []);

  //handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (!hadFormError()) {
    if (id) {
      let payload = { id: id, permissions: { ...formData } };
      dispatch(saveUpdateRolePermissions(payload));
    }
    // }
  };

  //on page load
  useEffect(() => {
    const permissionIds = roleDetails?.data?.permissions?.map(
      (permission) => permission.id
    );
    setFormData(permissionIds || []);
    // eslint-disable-next-line
  }, [roleDetails]);

  return (
    <div className="roles-details">
      <PageTitle
        heading={`Manage ${
          !roleDetails?.loading && roleDetails?.data
            ? _startCase(roleDetails?.data.name)
            : ''
        } Permissions`}
        buttonName="Listing"
        buttonClick={() => navigate('/roles')}
      />
      {roleDetails.loading ? (
        <Loader isFull />
      ) : !!roleDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <PermissionsCheckboxes
                permissions={
                  !Array.isArray(permissions?.data) ? permissions?.data : null
                }
                guardName={roleDetails?.data?.guard_name}
                formData={formData}
                onCheckboxChange={handleCheckboxChange}
                onSelectAllChange={handleSelectAllChange}
              />
            </Card.Body>
            <Card.Footer>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ marginRight: '10px' }}
                    disabled={saveUpdateStatus?.loading}
                  >
                    Update
                  </Button>
                  <Link
                    className={`button-link secondary ${
                      saveUpdateStatus?.loading ? 'disabled' : ''
                    }`}
                    to="/roles"
                  >
                    Cancel
                  </Link>
                </Col>
              </Row>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Page Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default RoleDetails;
