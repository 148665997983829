import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { IRouteParams } from '../../datatypes/CommonTypes';
import { fetchAnswers, resetAnswerData } from 'src/redux/slices/answerSlice';
import PageTitle from '../../components/common/PageTitle';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';
import BasicAnswers from '../../components/review-answers/BasicAnswers';
import FamilyInformationAnswers from '../../components/review-answers/FamilyInformationAnswers';
import GuardianAnswers from '../../components/review-answers/GuardianAnswers';
import BeneficiaryExclusionAnswers from '../../components/review-answers/BeneficiaryExclusionAnswers';
import PetsAnswers from '../../components/review-answers/PetsAnswers';
import DigitalAssetsAnswers from '../../components/review-answers/DigitalAssetsAnswers';
import SpecificBequestsAnswers from '../../components/review-answers/SpecificBequestsAnswers';
import CharityAnswers from '../../components/review-answers/CharityAnswers';
import ResiduaryPropertyAnswers from '../../components/review-answers/ResiduaryPropertyAnswers';
import ExecutorAnswers from '../../components/review-answers/ExecutorAnswers';
import LastWillProvisionAnswers from '../../components/review-answers/LastWillProvisionAnswers';
import NonProbateAssetsAnswers from '../../components/review-answers/NonProbateAssetsAnswers';
import FuneralPlanningAnswers from '../../components/review-answers/FuneralPlanningAnswers';
import SpecialInstructionAnswer from '../../components/review-answers/SpecialInstructionAnswer';
import { isEmpty } from 'lodash';
import { getMinorOrDisabledChildren } from 'src/redux/selectors/answerSelector';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import Loader from 'src/components/common/Loader';

const WillCreatorAnswers = () => {
  const { id }: IRouteParams = useParams();
  const navigate = useNavigate();
  //redux
  const dispatch = useAppDispatch();

  const {
    data: answers,
    loading,
    error,
  } = useAppSelector((state: any) => state.answers);

  const getWillAnswersDetailsData = () => {
    if (id) {
      dispatch(fetchAnswers(id));
    }
  };

  //handle getting data on page load.
  useEffect(() => {
    getWillAnswersDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //handle getting data on page load.
  useEffect(() => {
    if (error) {
      dispatch(resetAnswerData());
      navigate('/will-creators');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const minorOrDisabledChildren = useAppSelector((state) =>
    getMinorOrDisabledChildren(state)
  );

  return (
    <div>
      <PageTitle
        heading={`View Will Creator Answers `}
        buttonName={'Listing'}
        buttonClick={() => navigate('/will-creators')}
      />
      {loading ? (
        <Loader isFull />
      ) : (
        !isEmpty(answers) && (
          <>
            {answers[pageCodes.personalInfoPage] &&
              !isEmpty(answers[pageCodes.personalInfoPage]) && (
                <BasicAnswers answers={answers} />
              )}
            {answers &&
              answers[pageCodes.spouseInfoPage] &&
              !isEmpty(answers[pageCodes.spouseInfoPage]) && (
                <FamilyInformationAnswers answers={answers} />
              )}
            {answers[pageCodes.childrenInfoPage] &&
              answers[pageCodes.childrenInfoPage][questionCodes.haveChildren][
                questionInputKeys.HAS_CHILDREN
              ] === 'yes' &&
              minorOrDisabledChildren.length > 0 &&
              answers[pageCodes.guardianInfoPage] &&
              !isEmpty(answers[pageCodes.guardianInfoPage]) && (
                <GuardianAnswers answers={answers} />
              )}
            {answers[pageCodes.beneficiaryExclusionPage] &&
              !isEmpty(answers[pageCodes.beneficiaryExclusionPage]) && (
                <BeneficiaryExclusionAnswers answers={answers} />
              )}
            {answers[pageCodes.petsInfoPage] &&
              !isEmpty(answers[pageCodes.petsInfoPage]) && (
                <PetsAnswers answers={answers} />
              )}
            {answers[pageCodes.digitalAssetsPage] &&
              !isEmpty(answers[pageCodes.digitalAssetsPage]) && (
                <DigitalAssetsAnswers answers={answers} />
              )}
            {answers[pageCodes.specificBequestsPage] &&
              !isEmpty(answers[pageCodes.specificBequestsPage]) && (
                <SpecificBequestsAnswers answers={answers} />
              )}
            {answers[pageCodes.charitiesPage] &&
              !isEmpty(answers[pageCodes.charitiesPage]) && (
                <CharityAnswers answers={answers} />
              )}
            {((answers[pageCodes.charitiesPage] &&
              !isEmpty(answers[pageCodes.charitiesPage])) ||
              (answers[pageCodes.residuaryEstatePage] &&
                !isEmpty(answers[pageCodes.residuaryEstatePage]))) && (
              <ResiduaryPropertyAnswers answers={answers} />
            )}
            {answers[pageCodes.executorPage] &&
              !isEmpty(answers[pageCodes.executorPage]) && (
                <ExecutorAnswers answers={answers} />
              )}
            {answers[pageCodes.lastWillProvision] &&
              !isEmpty(answers[pageCodes.lastWillProvision]) && (
                <LastWillProvisionAnswers answers={answers} />
              )}
            {answers[pageCodes.nonProbateAssetsPage] &&
              !isEmpty(answers[pageCodes.nonProbateAssetsPage]) && (
                <NonProbateAssetsAnswers answers={answers} />
              )}
            {answers[pageCodes.funeralInfoPage] &&
              !isEmpty(answers[pageCodes.funeralInfoPage]) && (
                <FuneralPlanningAnswers answers={answers} />
              )}
            {answers[pageCodes.specialInstructionsPage] &&
              !isEmpty(answers[pageCodes.specialInstructionsPage]) && (
                <SpecialInstructionAnswer answers={answers} />
              )}
          </>
        )
      )}
    </div>
  );
};

export default WillCreatorAnswers;
