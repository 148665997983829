import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import useAuthService from '../../services/auth.service';
import Cookies from 'universal-cookie';
import { canUserAccess } from 'src/helpers/common';

const TheHeader = () => {
  const { logout } = useAuthService();
  const location = useLocation();
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [userName, setUserName] = useState<string>('');

  const handleLogout = async () => {
    await logout().then(() => {
      localStorage.clear();

      // Store the current path as the last visited page
      cookies.set('last_visited_page', location.pathname, {
        path: '/',
        maxAge: 86400,
      });

      navigate('/login');
    });
  };

  useEffect(() => {
    const adminUser = JSON.parse(localStorage.getItem('admin_user') ?? '');
    if (adminUser) {
      setUserName(`${adminUser.first_name} ${adminUser.last_name}`);
    }
  }, []);

  const userText = <span className="user-text">{userName}</span>;
  return (
    <header className="header header-fixed d-flex flex-wrap">
      <ul className="header-nav header-left w-50">
        {canUserAccess(['view-dashboard']) && (
          <li className="px-3 header-nav-item">
            <Link to="/">Dashboard</Link>
          </li>
        )}
        <li className="px-3 header-nav-item">
          <Link to="/settings">Settings</Link>
        </li>
      </ul>
      <div className="px-3 header-nav header-right w-50 justify-content-end">
        <NavDropdown title={userText} id="basic-nav-dropdown">
          <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
        </NavDropdown>
      </div>
    </header>
  );
};

export default TheHeader;
