import { Button } from 'react-bootstrap';

interface IProps {
  heading: string;
  buttonName?: string;
  buttonClick?: () => void;
  customButton?: any;
  border?: boolean;
  showButton?: boolean;
}

const PageTitle = ({
  buttonClick = () => {},
  buttonName = '',
  customButton = null,
  heading,
  border = true,
  showButton = true,
}: IProps) => {
  return (
    <div className={border ? 'page-header' : 'no-border-page-header'}>
      <h3>{heading}</h3>
      <div className="page-header-actions">
        {customButton}
        {buttonName && buttonClick && showButton && (
          <Button
            variant="primary"
            onClick={() => buttonClick && buttonClick()}
          >
            {buttonName}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PageTitle;
