import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import { IUsersPayload, IUsersRow } from '../../datatypes/User';

interface IProps {
  show: boolean;
  toggleDeleteModal: () => void;
  handleSubmit: () => void;
  selectedIdDetails: IUsersRow[];
  deleteUsersDetail: IUsersPayload['admin']['deleteAdmin'];
}

const DeleteModal = ({
  show,
  toggleDeleteModal,
  handleSubmit,
  selectedIdDetails,
  deleteUsersDetail,
}: IProps) => {
  return (
    <Modal show={show} onHide={toggleDeleteModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Do you want to delete user?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-user">
          You will delete
          {selectedIdDetails?.length > 0 &&
            selectedIdDetails.map((user: IUsersRow, index: number) => (
              <span className="mx-2">
                {user.first_name} {user.last_name}
                {selectedIdDetails?.length !== index + 1 && ', '}
              </span>
            ))}
          user!
        </div>
        <div></div>
      </Modal.Body>
      <Modal.Footer>
        <FowButton variant="secondary" onClick={toggleDeleteModal}>
          Cancel
        </FowButton>
        <FowButton
          variant="danger"
          onClick={handleSubmit}
          isLoading={deleteUsersDetail?.loading}
        >
          Delete
        </FowButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
