import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useRoleApi from 'src/api/role.api';
import { IRolePayload } from 'src/datatypes/Role';

// initial / template state of roles redux slice
const initialState: IRolePayload = {
  roles: {
    loading: false,
    error: false,
    success: false,
    data: [],
  },
  roleDetails: {
    loading: false,
    error: false,
    success: false,
    data: null,
  },
  saveUpdateStatus: {
    success: false,
    loading: false,
    error: false,
  },
};

// redux slice for roles
export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  // reducers that don't require API Calls
  reducers: {
    resetRoleDetails: (state: IRolePayload) => {
      state.roleDetails = initialState.roleDetails;
      state.saveUpdateStatus = initialState.saveUpdateStatus;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.roles.loading = true;
        state.roles.error = false;
        state.roles.success = false;
      })
      .addCase(fetchRoles.fulfilled, (state, { payload }) => {
        state.roles.data = payload.roles;
        state.roles.loading = false;
        state.roles.success = true;
      })
      .addCase(fetchRoles.rejected, (state) => {
        state.roles.loading = false;
        state.roles.error = true;
      })
      .addCase(fetchRoleDetails.pending, (state) => {
        state.roleDetails.loading = true;
        state.roleDetails.error = false;
        state.roleDetails.success = false;
      })
      .addCase(fetchRoleDetails.fulfilled, (state, { payload }) => {
        state.roleDetails.data = payload;
        state.roleDetails.loading = false;
        state.roleDetails.success = true;
      })
      .addCase(fetchRoleDetails.rejected, (state) => {
        state.roleDetails.loading = false;
        state.roleDetails.error = true;
      })
      .addCase(saveUpdateRolePermissions.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(saveUpdateRolePermissions.fulfilled, (state) => {
        state.saveUpdateStatus.success = true;
        state.saveUpdateStatus.loading = false;
      })
      .addCase(saveUpdateRolePermissions.rejected, (state) => {
        state.saveUpdateStatus.error = true;
        state.saveUpdateStatus.loading = false;
      });
  },
});

// Async API Hooks

/**
 * Fetch Roles List from API and save in redux store
 */
export const fetchRoles = createAsyncThunk(
  'roles/fetchRoles',
  async (_, { rejectWithValue }) => {
    const response = await useRoleApi().getRoles();
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Roles Details from API and save in redux store
 */
export const fetchRoleDetails = createAsyncThunk(
  'roles/fetchRoleDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useRoleApi().getRoleDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response.role;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Roles Details from API and save in redux store
 */
export const saveUpdateRolePermissions = createAsyncThunk(
  'roles/saveUpdateRolePermissions',
  async (data: { id: string; permissions: number[] }, { rejectWithValue }) => {
    const id = data.id;

    const response = await useRoleApi().saveUpdateRolePermissions(
      id,
      data.permissions
    );
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetRoleDetails } = roleSlice.actions;

export default roleSlice.reducer;
