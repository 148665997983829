import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { IShowDeleteMetaModal, IMetaPayload } from '../../datatypes/Meta';
import { resetMetaData, fetchMetas } from '../../redux/slices/metaSlice';
import Listing from '../../components/metadata/Listing';
import { canUserAccess } from 'src/helpers/common';

const MetaListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { metaList, deleteMetaStatus } = useAppSelector<IMetaPayload>(
    (state) => state.meta
  );

  const [showDeleteMetaModal, setShowDeleteMetaModal] =
    useState<IShowDeleteMetaModal>({
      show: false,
      idDetails: [],
    });

  const getMetaListData = () => {
    dispatch(fetchMetas());
  };

  useEffect(() => {
    getMetaListData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deleteMetaStatus.success) {
      toastSuccessMessage('Meta Details deleted successfully!');
      setShowDeleteMetaModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetMetaData());
      getMetaListData();
    }
    // eslint-disable-next-line
  }, [deleteMetaStatus]);

  return (
    <div className="meta-listing">
      <PageTitle
        heading="Meta Tags"
        buttonName="New"
        buttonClick={async () => {
          dispatch(resetMetaData());
          navigate('/meta-tags/new');
        }}
        showButton={canUserAccess(['create-meta-tag'])}
      />
      <div className="meta-listing-content">
        <Listing
          metaList={metaList}
          showDeleteModal={showDeleteMetaModal}
          setShowDeleteModal={setShowDeleteMetaModal}
          deleteMetaDetail={deleteMetaStatus}
        />
      </div>
    </div>
  );
};

export default MetaListing;
