import PageTitle from '../../components/common/PageTitle';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Listing from '../../components/roles/Listing';
import { fetchRoles } from 'src/redux/slices/roleSlice';
import { IRolePayload } from 'src/datatypes/Role';

const RoleListing = () => {
  const dispatch = useAppDispatch();

  const { roles } = useAppSelector<IRolePayload>((state) => state.roles);

  const getRolesListData = () => {
    dispatch(fetchRoles());
  };

  useEffect(() => {
    getRolesListData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="roles-listing">
      <PageTitle heading="Roles" />
      <div className="roles-listing-content">
        <Listing rolesList={roles} />
      </div>
    </div>
  );
};

export default RoleListing;
